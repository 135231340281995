import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { FreeChapterTable } from '../components/Tables'

export default props => {
  const id = props.match.params.id
  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <h4 onClick={() => window.history.back()}>
            <FontAwesomeIcon icon={faArrowLeft} style={{ cursor: 'pointer' }} /> All Chapters
          </h4>
          <p className='mb-0'>
            SHAH Chapters Management. or <a href={'#/create-free-chapter/' + id}>Create a Chapter</a>{' '}
          </p>
        </div>
      </div>

      <FreeChapterTable id={id} />
    </>
  )
}
