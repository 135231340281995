import React, { useState, useEffect } from 'react'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import htmlToDraft from 'html-to-draftjs'
// import { EditorState, convertToRaw, ContentState } from 'draft-js'
// import { Editor } from 'react-draft-wysiwyg'
// import draftToHtml from 'draftjs-to-html'
import S3FileUpload from 'react-s3'
import axios from 'axios'

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [chapterid, setChapterId] = useState(props.match.params.id)
  const [lock, setLock] = useState('')
  const [lessonNumber, setLessonNumber] = useState('')
  const [lessonImage, setLessonImage] = useState('')

  const [lessonVideo, setLessonVideo] = useState('')
  // const [lessonIframe, setLessonIframe] = useState('')

  const [lessonTime, setLessonTime] = useState('')

  const [lessonMaterial, setLessonMaterial] = useState('')

  const [id] = useState(props.match.params.id)

  const config = {
    bucketName: 'shah-university-courses',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHVJ7GHJBI',
    secretAccessKey: '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'
  }

  function UploadImage(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setLessonImage(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadMaterials(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setLessonMaterial(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  // const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  // const handleEditorChange = state => {
  //   setEditorState(state)
  // }

  // eslint-disable-next-line no-unused-vars
  // function RecieveData(html) {
  //   const contentBlock = htmlToDraft(html)
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  //     const editorState = EditorState.createWithContent(contentState)
  //     setEditorState(editorState)
  //   }
  // }

  useEffect(() => {
    LoadCourse()
  }, [chapterid])

  function LoadCourse() {
    axios
      .get(`/api/free-lesson/view/${id}`)
      .then(res => {
        if (res?.status === 200) {
          const response = res.data.data

          // RecieveData(response.lesson_about)
          setnameOfCourse(response.lesson_name)
          setChapterId(response.chapterid)
          setLock(response.lock === true ? 'yes' : 'no')
          setLessonImage(response.lesson_image)
          setLessonNumber(response.lesson_number)
          setLessonVideo(response.lesson_video)
          // setLessonIframe(response.lesson_iframe)

          setLessonTime(response.lesson_time)

          setLessonMaterial(response.lesson_material)

          // setEditorState(response.description)
        }
      })

      .catch(e => {
        alert(e)
        //  console.log(id)
      })
  }

  function CourseCreation(e) {
    e.preventDefault()
    convertContentToHTML()
  }

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, '-')
  //     .replace(/[^\w-]+/g, '')
  // }

  const convertContentToHTML = () => {
    // let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    axios
      .post(`/api/free-lesson/update/${id}`, {
        id: id,
        lock: lock.toLowerCase() === 'yes' ? true : false,
        lesson_name: nameOfCourse,
        // lessonAbout: currentContentAsHTML,
        lesson_image: lessonImage,
        lesson_video: lessonVideo,
        // lesson_iframe: lessonIframe,
        lesson_time: lessonTime,
        lesson_number: lessonNumber,
        lesson_material: lessonMaterial
      })
      .then(() => {
        alert('Lesson Updated')
        window.history.back()
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Edit Lesson</h1>
            <p className='mb-0'>Here you can edit lesson for SHAH</p>
          </Col>
        </Row>

        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Lesson Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Course name'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            {/* <Form.Label>Add Description</Form.Label>

            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            /> */}
            <Form.Group className='mb-3'>
              {/* <Form.Label> Lesson Mains ( YES / NO )</Form.Label>
              <br /> */}
              {/* <Form.Control style={{display:"none"}} value={chapterid} onChange={e=>setChapterId(e.target.value)} style={{marginTop:4}} type="text" placeholder="Chapter Id"  required/> */}
              <Form.Label style={{}}>Lock</Form.Label>
              <Form.Control
                value={lock}
                onChange={e => setLock(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Lock (YES / NO)'
                required
              />
              <Form.Label style={{ marginTop: 12 }}>Lesson Number</Form.Label>
              <Form.Control
                value={lessonNumber}
                onChange={e => setLessonNumber(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                required
              />
              <Form.Label style={{ marginTop: 12 }}>Lesson Image (JPG & PNG)</Form.Label> <br />
              {lessonImage.length === 0 ? (
                <input onChange={UploadImage} style={{ marginTop: 4 }} type='file' placeholder='Lesson Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setLessonImage('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>VdoCipher Lesson Id</Form.Label>
              <Form.Control
                value={lessonVideo}
                onChange={e => setLessonVideo(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Lesson Video URL'
                required
              />
              {/* <Form.Label style={{ marginTop: 12 }}>Lesson VdoCipher Iframe</Form.Label>
              <Form.Control
                value={lessonIframe}
                onChange={e => setLessonIframe(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Lesson Iframe'
                required
              /> */}
              <Form.Label style={{ marginTop: 12 }}>Lesson in minutes</Form.Label>
              <Form.Control
                value={lessonTime}
                onChange={e => setLessonTime(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Lesson Time'
                required
              />
              <Form.Label style={{ marginTop: 12 }}>Lesson material ( pdf, doc or zip)</Form.Label> <br />
              {lessonMaterial.length === 0 ? (
                <input onChange={UploadMaterials} style={{ marginTop: 4 }} type='file' placeholder='Lesson Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setLessonMaterial('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
            </Form.Group>

            <button className='btn btn-info' type='submit'>
              {' '}
              Update Lesson
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
