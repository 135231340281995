import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, Breadcrumb, Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import toast from 'react-hot-toast'
import { StudentsTable } from '../../../components/free-course-table'
import { callApi } from '../../../utils/api-utils'

import * as XLSX from 'xlsx/xlsx.mjs'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const studentListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/free-course-user/list'
}

const assignFreeUserCourse = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/assign-course'
}

const studentApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/update-approval-status'
}

const academicYearData = [
  { u_id: 1, value: '1', label: '1st year' },
  { u_id: 2, value: '2', label: '2nd year' },
  { u_id: 3, value: '3', label: '3rd year' },
  { u_id: 4, value: '4', label: '4th year' },
  { u_id: 5, value: '5', label: 'Internship' },
  { u_id: 6, value: '6', label: 'MD Part 1' },
  { u_id: 7, value: '7', label: 'MD Part 2' }
]

const UniversityStudent = props => {
  // ** id
  const id = props.match.params.id

  // ** states
  const [universityList, setUniversityList] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [academicYear, setAcademicYear] = useState('')
  const [gender, setGender] = useState('')
  const [courses, setCourses] = useState([])
  const [showAssignModal, setShowAssignModal] = useState(false)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [dateError, setDateError] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [studentList, setStudentList] = useState([])
  const [recallApi, setRecallApi] = useState('')
  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  const handleClose = () => setShowModal(false)

  async function approveStatus() {
    // First, filter the students whose approval status is NOT 'approve'
    const studentsToUpdate = studentList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status !== selectedApproveStatus
    )

    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      toast.error('No students need approval status update.')
      return
    }

    let body = {
      approval_status: selectedApproveStatus,
      students: idsToUpdate
    }

    try {
      // Send the API request
      await callApi({ uriEndPoint: studentApproveStatusUpdate, body })

      // Refresh the data and reset states
      setRecallApi(Math.floor(Math.random(1) * 100).toString())
      setShowModal(false)
      setSelectedApproveStatus(null)
      setSelectedStudentIds([])
    } catch (e) {
      console.error('Error updating approval status:', e)
    }
  }

  const handelChangeApprove = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  // ** common student list

  async function StudentListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        pursuing_year: academicYear,
        gender,
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleExportToExcel = () => {
    // Convert the student list data to a format suitable for Excel
    const worksheetData = studentList.map(student => ({
      'Student Id': student?.student_id ? student?.student_id : '-',
      Name: student?.full_name ? student?.full_name : '-',
      Email: student?.email ? student?.email : '-',
      Phone: student?.phone ? student?.phone : '-',
      Gender: student?.gender ? student?.gender : '-',
      University: student?.university_name ? student?.university_name : '-',
      'Pursuing Year': student?.pursuing_year ? student?.pursuing_year : '-',
      'Approval Status': student?.approval_status
        ? student?.approval_status === 'approve'
          ? 'approved'
          : student?.approval_status
        : '-'
      // Add more fields here as needed
    }))

    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(worksheetData)

    // Create a new workbook with the worksheet
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Students')

    // Write the workbook to a binary string
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    // Create a Blob from the binary string
    const file = new Blob([excelBuffer], { type: 'application/octet-stream' })

    // Trigger the download of the file
    const fileName = `students-${new Date().toLocaleDateString()}.xlsx` // Dynamically generate file name with today's date

    // Create an anchor element to initiate the download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(file)
    link.download = fileName

    // Programmatically trigger a click event to download the file
    link.click()
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    StudentListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage])

  // ** common course list
  async function Refresh() {
    await axios
      .get('/api/free-course/list?type=front-end-api')
      .then(res => {
        const data = res?.data?.allFreeCourse || []
        data.sort(function (a, b) {
          let keyA = a.serial
          let keyB = b.serial

          if (keyA < keyB) return -1
          if (keyA > keyB) return 1
          return 0
        })

        setCourses(data)
      })
      .catch(e => alert(e))
  }

  // ** common pursuing year
  // Add event handlers for new filters
  const handleAcademicYearChange = e => {
    setAcademicYear(e.target.value)
  }

  // ** assign course
  // Validate that end date is not earlier than start date
  const validateDates = () => {
    if (startDate && endDate) {
      const start = new Date(startDate)
      const end = new Date(endDate)
      return end >= start
    }
    return true
  }

  // Handle form changes for dates and validate
  const handleStartDateChange = e => {
    setStartDate(e.target.value)
    if (endDate && new Date(e.target.value) > new Date(endDate)) {
      setDateError(true) // Set error if start date is after end date
    } else {
      setDateError(false)
    }
  }

  const handleEndDateChange = e => {
    setEndDate(e.target.value)
    if (new Date(startDate) > new Date(e.target.value)) {
      setDateError(true) // Set error if end date is before start date
    } else {
      setDateError(false)
    }
  }

  const handleGenderChange = e => {
    setGender(e.target.value)
  }

  const handleAssignShow = () => setShowAssignModal(true)
  const handleAssignClose = () => {
    setShowAssignModal(false)
    setDateError(false)
    setStartDate('')
    setEndDate('')
    setSelectedCourses([])
  }

  // Store selected course IDs
  const handleCourseSelect = e => {
    const courseId = e.target.value
    setSelectedCourses(prev => (e.target.checked ? [...prev, courseId] : prev.filter(id => id !== courseId)))
  }

  const handleAssign = async () => {
    const studentsToUpdate = studentList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status === 'approve'
    )
    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate?.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      // alert('No students with approval status "approve".')
      toast.error('No students with approval status "approve".')
      return
    }

    const body = {
      courses: selectedCourses,
      students: idsToUpdate,
      start_date: startDate,
      end_date: endDate,
      max_watch: 1
    }

    if (selectedCourses || idsToUpdate) {
      try {
        const res = await callApi({
          uriEndPoint: assignFreeUserCourse,
          body: body
        })

        if (res) {
          // Reset form fields after successful assignment
          setSelectedStudentIds([])
          setShowAssignModal(false)
          setDateError(false)
          setStartDate('')
          setEndDate('')
          setSelectedCourses([])

          toast.success(res?.message ?? 'Courses successfully assigned!')
        }
      } catch (error) {
        toast.error(error?.message ?? 'Error occurred while assigning courses.')
      }
    }
  }

  // Search button handler common
  const handleSearch = async () => {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        pursuing_year: academicYear,
        gender,
        page: currentPage, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint, query: { status: true } }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    setSelectedUniversity(e.target.value)
  }

  useEffect(() => {
    Refresh()
  }, [])

  // Effect to set default dates if not provided
  useEffect(() => {
    const today = new Date()
    const end = new Date(today)
    end.setMonth(end.getMonth() + 10)

    // If no startDate is selected, set it to today's date
    if (!startDate) {
      setStartDate(today.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }

    // If no endDate is selected, set it to 10 months from today
    if (!endDate) {
      setEndDate(end.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }
  }, [startDate, endDate])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Student and Assign Courses</h4>
        </div>
      </div>
      <div className=''>
        <div className='row gy-3 gx-3 mb-3'>
          {/* Uni */}
          <div className='form-group col-12 col-sm-6 col-lg-4'>
            <label htmlFor='inputPassword4'>Select University</label>
            <select
              id='inputState'
              value={selectedUniversity || ''} // value is controlled by state
              onChange={handelChange}
              name='university_id'
              className='form-control'
            >
              {/* Add a default placeholder option */}
              <option value='' disabled>
                Select University
              </option>
              {/* Populate options dynamically */}
              {universityList &&
                universityList?.allUniversity?.map(university => (
                  <option key={university?._id} value={university?._id}>
                    {university?.name}
                  </option>
                ))}
            </select>
          </div>
          {/* Year */}
          <div className='form-group col-12 col-sm-6 col-lg-3'>
            <label htmlFor='academicYear'>Pursuing Year</label>
            <select id='academicYear' value={academicYear} onChange={handleAcademicYearChange} className='form-control'>
              <option value='active'>All Active</option>
              {/* Populate pursuing year options */}
              {academicYearData?.map(item => (
                <option key={item?.u_id} value={item?.value}>
                  {item?.label}
                </option>
              ))}
              <hr />
              <option value='archive' className='fw-bold'>
                ARCHIVE
              </option>
            </select>
          </div>
          {/* Gender */}
          <div className='form-group col-12 col-sm-6 col-lg-3'>
            <label htmlFor='gender'>Gender</label>
            <select id='gender' value={gender} onChange={handleGenderChange} className='form-control'>
              <option value=''>All</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
          {/* Search */}
          <div className='col-sm-6 col-12 col-lg-2 d-flex align-items-end justify-content-center'>
            <button className='btn btn-primary w-100' onClick={() => handleSearch()}>
              Search
            </button>
          </div>
        </div>
        <div className='d-flex w-100 justify-content-start gap-3 mb-3'>
          {/* Assign */}
          <div>
            <button className='btn btn-secondary' onClick={handleAssignShow} disabled={!selectedStudentIds?.length}>
              Assign Course(s)
            </button>
          </div>
          {/* Export */}
          <div>
            <button className='btn btn-success' onClick={handleExportToExcel} disabled={studentList.length === 0}>
              Export to Excel
            </button>
          </div>
        </div>

        <StudentsTable
          id={id}
          selectedUniversity={selectedUniversity}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          studentList={studentList}
          setRecallApi={setRecallApi}
          StudentListData={() => StudentListData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      <Modal show={showAssignModal} onHide={handleAssignClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Assign Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <p>This will change the assign below selected courses to student(s)!</p>
            <p>No. of selected student(s) : {selectedStudentIds?.length}</p>
            {/* Row for Start Date, End Date, and Watch Count */}
            <Form.Group controlId='dateAndCount' className='mb-2'>
              <Row>
                <Col md={4}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control type='date' value={startDate} onChange={handleStartDateChange} />
                </Col>
                <Col md={4}>
                  <Form.Label>End Date</Form.Label>
                  <Form.Control type='date' value={endDate} onChange={handleEndDateChange} />
                </Col>
                <Col md={4}>
                  <Form.Label>Watch Count</Form.Label>
                  <Form.Control
                    readOnly
                    type='number'
                    value={1}
                    // onChange={e => setWatchCount(e.target.value)}
                    required
                  />
                </Col>
                {dateError && (
                  <Alert variant='danger' className='mt-2'>
                    End Date cannot be earlier than Start Date.
                  </Alert>
                )}
              </Row>
            </Form.Group>

            {/* Searchable Course Selection */}
            <Form.Group controlId='courses' className='mb-2 '>
              <Form.Label>Select Course(s)</Form.Label>
              {courses.map(course => (
                <Form.Check
                  id={course._id}
                  key={course._id}
                  type='checkbox'
                  label={course.name_of_course}
                  value={course._id}
                  checked={selectedCourses?.includes(course._id)}
                  onChange={e => handleCourseSelect(e, course._id)}
                  className='cursor-pointer-default'
                />
              ))}
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAssign} disabled={!validateDates() || selectedCourses?.length === 0}>
            Confirm Assign Course
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChangeApprove}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approve</option>
                <option value='pending'>Pending</option>
                <option value='cancel'>Cancel</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={approveStatus} disabled={!selectedApproveStatus || !selectedCourses}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UniversityStudent
