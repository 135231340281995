import { Col, Container, Form, Row } from '@themesberg/react-bootstrap'
import React, { useState } from 'react'

import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import 'primereact/resources/themes/lara-light-cyan/theme.css'

window.Buffer = window.Buffer || require('buffer').Buffer

import axios from 'axios'
import toast from 'react-hot-toast'

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  const [courseid, setCourseId] = useState(props.match.params.id)
  const [clinicalPonits, setClinicalPonits] = useState('')
  const [chapterNumber, setChapterNumber] = useState('')
  // const [oneLineSentence, setOneLineSentence] = useState('')
  // const [chapterRating, setChapterRating] = useState('')
  // const [chapterDuration, setChapterDuration] = useState('')
  // const [chapterOverView, setChapterOverView] = useState('')
  // const [curriculumUrl, setCurriculumUrl] = useState('')
  const [overViewId, setOverViewId] = useState('')

  // const [HeroImg, setHeroImg] = useState('')

  // const [courses, setCourses] = useState([])
  // const [selectedCourse, setSelectedCourse] = useState(null)

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  function CourseCreation(e) {
    e.preventDefault()
    convertContentToHTML()
  }

  async function convertContentToHTML() {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // const filterId = selectedCourse.map(i => i._id)

    const postBody = {
      chapterName: nameOfCourse,
      chapterDes: currentContentAsHTML,
      freeCourseId: courseid,
      clinicalPonits: clinicalPonits,
      chapterNumber: chapterNumber,
      // chapter_thumbnail: HeroImg,
      // related_course_array: filterId,
      // one_line_sentence: oneLineSentence,
      // chapter_duration: chapterDuration,
      // chapter_poster_image: HeroImg,
      // rating: chapterRating,
      // over_view: chapterOverView,
      // curriculum_url: curriculumUrl,
      mobile_id: overViewId
    }

    await axios
      .post('/api/free-chapter/create/chapter', postBody)
      .then(() => {
        toast.success('Chapter Created')

        window.location.href = `#/all-free-chapters/${courseid}`

        // console.log(res)
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Create Free Course Chapter</h1>
            <p className='mb-0'>
              Here you can create a chapter for SHAH <a href={'#/all-free-chapters/' + courseid}>Back to chapters</a>
            </p>
          </Col>
        </Row>
        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Chapter name'
              required
            />
          </Form.Group>
          {/* <Form.Group className='mb-3'>
            <Form.Label>One Line Sentence</Form.Label>
            <Form.Control
              type='text'
              value={oneLineSentence}
              onChange={e => setOneLineSentence(e.target.value)}
              placeholder='One Line Sentence'
            />
          </Form.Group> */}
          {/* <Form.Group className='mb-3'>
            <Form.Label>Chapter Rating</Form.Label>
            <Form.Control
              type='text'
              value={chapterRating}
              onChange={e => setChapterRating(e.target.value)}
              placeholder='Chapter Rating'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Chapter Duration</Form.Label>
            <Form.Control
              type='text'
              value={chapterDuration}
              onChange={e => setChapterDuration(e.target.value)}
              placeholder='Chapter Duration'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Course Overview</Form.Label>
            <Form.Control
              type='text'
              value={chapterOverView}
              onChange={e => setChapterOverView(e.target.value)}
              placeholder='Course Overview'
            />
          </Form.Group> */}
          <Form.Group className='mb-3'>
            <Form.Label>Course Overview (Vdocipher Video Id)</Form.Label>
            <Form.Control
              type='text'
              value={overViewId}
              onChange={e => setOverViewId(e.target.value)}
              placeholder='Course Overview (Vdocipher Video Id)'
            />
          </Form.Group>
          {/* <Form.Group className='mb-3'>
            <Form.Label>Curriculum Url</Form.Label>
            <Form.Control
              type='text'
              value={curriculumUrl}
              onChange={e => setCurriculumUrl(e.target.value)}
              placeholder='Curriculum Url'
            />
          </Form.Group> */}
          {/* <Form.Group className='mb-3 card flex justify-content-center'>
            <MultiSelect
              value={selectedCourse}
              onChange={e => setSelectedCourse(e.value)}
              options={courses}
              optionLabel='name_of_course'
              placeholder='Select Course'
              maxSelectedLabels={3}
              className='w-full md:w-20rem'
            />
          </Form.Group> */}
          <Form.Group className='mb-3'>
            <Form.Label>Add Description</Form.Label>
            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Clinical Points ( 1 = default | 0 = hide )</Form.Label>
              <Form.Control
                value={clinicalPonits}
                onChange={e => setClinicalPonits(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Clinical Points'
                required
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label>Chapter Number </Form.Label>
              <Form.Control
                value={chapterNumber}
                onChange={e => setChapterNumber(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Chapter Number'
                required
              />
            </Form.Group>

            {/* <Form.Label style={{ marginTop: 4 }}>Chapter Hero Image</Form.Label>
            <div className='mb-3'>
              {HeroImg?.length === 0 ? (
                <input onChange={UploadImages} style={{ marginTop: 4 }} type='file' placeholder='Chapter Img URL' />
              ) : (
                <div>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setHeroImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </div>
              )}
            </div> */}
            <Form.Group style={{ marginTop: 4, display: 'none' }} className='mb-3'>
              <Form.Label>Details</Form.Label>
              <Form.Control
                value={courseid}
                onChange={e => setCourseId(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Your Course id'
                required
              />
            </Form.Group>
            <button className='btn btn-info' style={{ marginTop: 12 }} type='submit'>
              Add Chapter
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
