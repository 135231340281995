import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Card, Col, Form, Modal, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { StudentsAssignCourseTable } from '../../../components/free-course-table'
import { callApi } from '../../../utils/api-utils'
import toast from 'react-hot-toast'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const studentListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/admin/dashboard'
}

const studentApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/update-approval-status'
}

const UniversityFacultyCourse = props => {
  // ** id
  const id = props.match.params.id

  const [selectedStudentIds, setSelectedStudentIds] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [studentList, setStudentList] = useState({})
  const [recallApi, setRecallApi] = useState('')
  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  const handleClose = () => setShowModal(false)

  const handleShow = () => {
    setShowModal(true) // Open the modal when the button is clicked
  }

  async function approveStatus() {
    // First, filter the students whose approval status is NOT 'approve'
    const studentsToUpdate = studentList?.pending_student_list?.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status !== selectedApproveStatus
    )

    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      toast.error('No student need approval status update.')
      return
    }

    let body = {
      approval_status: selectedApproveStatus,
      students: idsToUpdate
    }

    try {
      // Send the API request
      await callApi({ uriEndPoint: studentApproveStatusUpdate, body })

      // Refresh the data and reset states
      setRecallApi(Math.floor(Math.random(1) * 100).toString())
      setShowModal(false)
      setSelectedApproveStatus(null)
      setSelectedStudentIds([])
      toast.success('Student status change Successfully!')
    } catch (e) {
      toast.error(e?.message ?? 'Student status not change')
      console.error('Error updating approval status:', e)
    }
  }

  const handelChangeApprove = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  // ** common student list

  async function StudentListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res.data.pending_student_list?.length)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    StudentListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage])

  // Effect to set default dates if not provided
  useEffect(() => {
    const today = new Date()
    const end = new Date(today)
    end.setMonth(end.getMonth() + 10)

    // If no startDate is selected, set it to today's date
    if (!startDate) {
      setStartDate(today.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }

    // If no endDate is selected, set it to 10 months from today
    if (!endDate) {
      setEndDate(end.toISOString().split('T')[0]) // Formats the date as 'YYYY-MM-DD'
    }
  }, [startDate, endDate])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>University Dashboard</h4>
        </div>
      </div>

      <Row className='g-4'>
        {/* University Count */}
        <Col xs={12} md={3}>
          <Card className='shadow-sm'>
            <Card.Body>
              <Card.Title>Universities</Card.Title>
              <Card.Text>
                <h2>{studentList?.university_count}</h2>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Student Count Card */}
        <Col xs={12} md={3}>
          <Card className='shadow-sm'>
            <Card.Body>
              <Card.Title>Students</Card.Title>
              <Card.Text>
                <h2>{studentList?.students_count ?? 0}</h2>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Coordinator Card */}
        <Col xs={12} md={3}>
          <Card className='shadow-sm'>
            <Card.Body>
              <Card.Title>Coordinators</Card.Title>
              <Card.Text>
                <h2>{studentList?.coordinator_count}</h2>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        {/* Course Count Card */}
        <Col xs={12} md={3}>
          <Card className='shadow-sm'>
            <Card.Body>
              <Card.Title>Courses</Card.Title>
              <Card.Text>
                <h2>{studentList?.free_course_count}</h2>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className=''>
        <div className='d-flex  justify-content-end flex-wrap flex-md-nowrap align-items-center my-3'>
          <button className='btn btn-primary' onClick={() => handleShow()} disabled={!selectedStudentIds?.length}>
            Change Status
          </button>
        </div>

        <StudentsAssignCourseTable
          id={id}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          studentList={studentList?.pending_student_list || []}
          setRecallApi={setRecallApi}
          StudentListData={() => StudentListData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will change the approval status of student(s)!</p>
          <p>No. of selected student(s) : {selectedStudentIds?.length}</p>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChangeApprove}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approved</option>
                <option value='cancel'>Cancelled</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' primary onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='secondary' onClick={approveStatus} disabled={!selectedApproveStatus}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showAssignModal} onHide={handleAssignClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Courses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='coursesSelect'>
            <Form.Label>Choose Courses</Form.Label>
            <Form.Control as='select' multiple value={selectedCourses} onChange={handleCourseSelect}>
              {courses.map(course => (
                <option key={course._id} value={course._id}>
                  {course.name_of_course}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
            }}
          >
            Confirm Assignment
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  )
}

export default UniversityFacultyCourse
