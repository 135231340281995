import React, { useState } from 'react'
import { callApi } from '../../../utils/api-utils'
import { Breadcrumb, Button, Form } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const addUniversityEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/university/add'
}

const AddUniversity = () => {
  const [addFiled, setAddFiled] = useState({ name: '', university_id: '', email: '', phone: '', status: true })
  const [errorHandel, setErrorHandel] = useState(false)
  const requiredFields = ['name', 'email', 'phone']

  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    phone: ''
  })

  const validateEmail = email => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  const validatePhone = phone => {
    const phoneRegex = /^\+?\d{1,2}[-\s]?\(?\d{1,4}\)?[-\s]?\d{1,4}[-\s]?\d{1,4}$/
    return phoneRegex.test(phone)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    // const checkValue = Object.values(addFiled).every(v => v)
    const checkValue = requiredFields?.every(field => addFiled[field]?.trim() !== '')

    // Validation checks
    let errors = {
      name: '',
      email: '',
      phone: '',
      password: ''
    }
    if (!validateEmail(addFiled.email)) {
      errors.email = 'Invalid email format'
    }
    if (!validatePhone(addFiled.phone)) {
      errors.phone = 'Invalid phone number format'
    }
    if (!addFiled?.name?.length) {
      errors.name = 'Please enter a name'
    }

    setValidationErrors(errors)

    if (checkValue && !errors.email && !errors.phone) {
      await callApi({ uriEndPoint: addUniversityEndPoint, body: addFiled })
        .then(res => {
          toast.success(res?.message ?? 'University created successfully!')
          setAddFiled({ name: '', university_id: '', email: '', phone: '', status: false })
          window.history.back()
        })
        .catch(e => toast.error(e?.message ?? 'University not created!'))
    } else {
      setErrorHandel(true)
    }
  }

  const handelChange = e => {
    const { name, value } = e.target

    // Update form field value
    setAddFiled({ ...addFiled, [name]: value })

    // Validate input dynamically on change
    let newValidationErrors = { ...validationErrors }

    // Check each field if it's being changed and validate accordingly
    if (name === 'email') {
      newValidationErrors.email = validateEmail(value) ? '' : 'Invalid email format'
    }
    if (name === 'phone') {
      newValidationErrors.phone = validatePhone(value) ? '' : 'Invalid phone number format'
    }

    setValidationErrors(newValidationErrors)
  }

  const handleStatusChange = e => {
    setAddFiled({ ...addFiled, status: e.target.checked })
  }

  return (
    <>
      <Breadcrumb
        className='d-none d-md-inline-block'
        listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
      >
        <Breadcrumb.Item>
          <FontAwesomeIcon icon={faHome} />
        </Breadcrumb.Item>
        <Breadcrumb.Item>SHAH</Breadcrumb.Item>
        <Breadcrumb.Item href='/#/assign-course'>All University</Breadcrumb.Item>
        <Breadcrumb.Item active>Create University</Breadcrumb.Item>
        {/* <Breadcrumb.Item active>{props?.match?.params?.id}</Breadcrumb.Item> */}
      </Breadcrumb>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <div className='d-flex justify-content-between align-items-center'>
            <h4>Create University</h4>
            {/* <button className='btn btn-primary' onClick={() => window.history.back()}>
              Back
            </button> */}
          </div>

          <form onSubmit={handleSubmit}>
            <div className='row gy-4 mb-3'>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Name*
                </label>
                <input
                  type='text'
                  name='name'
                  value={addFiled?.name || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='name'
                  maxLength={150}
                />
                {validationErrors.name && <small style={{ color: 'red' }}>{validationErrors.name}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  University Id
                </label>
                <input
                  type='text'
                  name='university_id'
                  value={addFiled?.university_id || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  placeholder='university id'
                  maxLength={20}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Email*
                </label>
                <input
                  type='email'
                  name='email'
                  value={addFiled?.email || ''}
                  onChange={handelChange}
                  className='form-control'
                  id='inputEmail4'
                  maxLength={60}
                  placeholder='name@company.com'
                />
                {validationErrors.email && <small style={{ color: 'red' }}>{validationErrors.email}</small>}
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='inputEmail4' className='mb-1'>
                  Phone number*
                </label>
                <input
                  type='text'
                  name='phone'
                  value={addFiled?.phone || ''}
                  onChange={handelChange}
                  className='form-control'
                  maxLength={13}
                  id='inputEmail4'
                  placeholder='919999900000'
                />
                {validationErrors.phone && <small style={{ color: 'red' }}>{validationErrors.phone}</small>}
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='inputState' className='mb-1'>
                  Status
                </label>
                <Form.Check // prettier-ignore
                  type='switch'
                  id='custom-switch'
                  data-toggle='modal'
                  data-target='#exampleModalCenterStatus'
                  checked={addFiled.status}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
            {errorHandel && (
              <h6 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>* required field</h6>
            )}
            <div className='d-flex align-items-center '>
              <Button type='submit' className='btn btn-primary mt-2 me-2'>
                Submit
              </Button>
              <Button className='btn btn-primary mt-2' onClick={() => window.history.back()}>
                Back
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddUniversity
