import { Col, Container, Form, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import S3FileUpload from 'react-s3'
window.Buffer = window.Buffer || require('buffer').Buffer

import axios from 'axios'

export default props => {
  const [nameOfCourse, setnameOfCourse] = useState('')
  const [slugValue, setSlugValue] = useState('')
  const [oneLineSentence, setOneLineSentence] = useState('')

  const [Img, setImg] = useState('')

  // const [tutorName, setTutorName] = useState('')
  // const [tutorImg, setTutorImg] = useState('')
  // const [tutorBio, setTutorBio] = useState('')
  const [published, setPublished] = useState('')

  const [rating, setRating] = useState('')

  const [id] = useState(props.match.params.id)
  const [
    ,
    // loading
    setLoading
  ] = useState('Load Course')

  // const ids= props.match.params.id
  const [notes, setNumberOfNotes] = useState('')
  const [certification, setCertificationType] = useState('')
  // const [overview, setOverview] = useState('')
  const [perview, setPreview] = useState('')
  const [serial, setSerial] = useState('')
  const [category, setCategory] = useState('')

  // const [clinicalPoints, setclinicalPoints] = useState('')
  const [BadgeHonor, setBadgeHonor] = useState('')
  const [liveShah, setliveShah] = useState('')
  const [CourseAccess, setCourseAccess] = useState('')
  // const [ChatwithCoach, setChatwithCoach] = useState('')
  const [TotalDuration, setTotalDuration] = useState('')
  const [Language, setLanguage] = useState('')
  const [SkillLevel, setSkillLevel] = useState('')
  const [divide, setDivide] = useState('')
  const [HeroImg, setHeroImg] = useState('')
  const [courseOverviewThumb, setcourseOverviewThumb] = useState('')
  const [courseCurriculum, setCourseCurriculum] = useState('')
  // const [awsoverview, setAwsOverview] = useState('')

  // const [selectCoach, setSelectCoach] = useState('')
  const [coursePublished, setCoursePublished] = useState('publish')

  useEffect(() => {
    LoadCourses(id)
  }, [])

  const config = {
    bucketName: 'shah-university-courses',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHVJ7GHJBI',
    secretAccessKey: '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'
  }

  function UploadImage(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setImg(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadImages(event) {
    const image = event.target.files[0]
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setHeroImg(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadImagesH(event) {
    const image = event.target.files[0]

    S3FileUpload.uploadFile(image, config)
      .then(data => {
        console.log('thumbOverview-S3FileUpload', data)
        setcourseOverviewThumb(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  function UploadCourseCurriculum(event) {
    const image = event.target.files[0]
    console.log(image)
    S3FileUpload.uploadFile(image, config)
      .then(data => {
        setCourseCurriculum(data.location)
        console.log(data.location)
      })
      .catch(err => console.error(err))
  }

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  // eslint-disable-next-line no-unused-vars
  function RecieveData(html) {
    const contentBlock = htmlToDraft(html)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }

  function LoadCourses() {
    setLoading('Loading ...')
    axios
      .get(`/api/free-course/view/${id}`)
      .then(res => {
        setLoading('Data Loaded')
        if (res.status === 200) {
          // alert(' Course Data Loaded')
          const response = res?.data?.data
          console.log('thumbOverview-view-res', res)
          // setSelectCoach(response?.select_coach || '')
          setCoursePublished(response?.is_published === false ? 'draft' : 'publish' || '')
          setSlugValue(response?.slug || '')
          setOneLineSentence(response?.one_line_sentence || '')
          setnameOfCourse(response?.name_of_course)

          setPublished(response?.published === true ? 'yes' : 'no')
          setImg(response?.image)
          // setTutorBio(response?.tutor_bio)
          // setTutorImg(response?.tutor_image)
          // setTutorName(response?.tutor_name)

          RecieveData(response?.description)
          setRating(response?.rating)

          setNumberOfNotes(response?.notes)
          setCertificationType(response?.certification)
          // setOverview(response?.overview)
          setPreview(response?.preview)
          setSerial(response?.serial)
          setCategory(response?.category)

          // setclinicalPoints(response?.clinical_points)
          setBadgeHonor(response?.badge_honor)
          setliveShah(response?.live_shah)

          setCourseAccess(response?.course_access)
          // setChatwithCoach(response?.chat_with_coach)
          setTotalDuration(response?.total_duration)
          setLanguage(response?.language)
          setSkillLevel(response?.skill_level)
          setDivide(response?.divide)
          setHeroImg(response?.banners)
          setcourseOverviewThumb(response?.course_overview_thumb)
          setCourseCurriculum(response?.course_curriculum)
          // setAwsOverview(response?.aws_overview)
        }
      })
      .catch(e => {
        console.log(`🚀  ~ file: EditFreeCourse.js:218 ~ LoadCourses ~ e:`, e)
        // setLoading('Data Load')
        // alert(e)
      })
  }

  function CourseCreation(e) {
    e.preventDefault()
    if (Img.length !== 0) {
      convertContentToHTML()
    } else {
      alert('Course Image not found')
    }
  }

  // function convertToSlug(Text) {
  //   return Text.toLowerCase()
  //     .replace(/ /g, '-')
  //     .replace(/[^\w-]+/g, '')
  // }

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    axios
      .post(`/api/free-course/update/${id}`, {
        id: id,
        published: published.toLowerCase() === 'yes' ? true : false,
        name_of_course: nameOfCourse,
        slug: slugValue,
        one_line_sentence: oneLineSentence,
        image: Img,
        rating: rating,
        description: currentContentAsHTML,
        course_curriculum: courseCurriculum,
        // select_coach: selectCoach,

        certification: certification,
        notes: notes,
        // overview: overview,
        preview: perview,
        serial: serial,
        is_published: coursePublished === 'draft' ? false : true,

        // tutor_name: tutorName,
        // tutor_image: tutorImg,
        // tutor_bio: tutorBio,
        category: category,

        //  clinical_points: clinicalPoints,
        badge_honor: BadgeHonor,
        live_shah: liveShah,

        course_access: CourseAccess,
        //  chat_with_coach: ChatwithCoach,
        total_duration: TotalDuration,
        language: Language,
        skill_level: SkillLevel,
        divide: divide,
        banners: HeroImg,
        course_overview_thumb: courseOverviewThumb
        // aws_overview: awsoverview
      })
      .then(res => {
        alert('Free Course Updated')
        console.log('thumbOverview-update-res', res)

        window.location.href = '#/free-course'
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2'>Edit Course</h1>
            <p className='mb-0'>Here you can edit course for SHAH</p>
          </Col>
        </Row>

        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label className='mt-2'>Course Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Your Course name'
            />
            <Form.Label className='mt-2'>Course Slug</Form.Label>
            <Form.Control
              type='text'
              value={slugValue}
              onChange={e => setSlugValue(e.target.value)}
              placeholder='Your Course name'
            />
            <Form.Label className='mt-2'>One Line Sentence</Form.Label>
            <Form.Control
              type='text'
              value={oneLineSentence}
              onChange={e => setOneLineSentence(e.target.value)}
              placeholder='One Line Sentence'
            />

            <Form.Label className='mt-2'>Rating</Form.Label>
            <Form.Control
              type='number'
              value={rating}
              onChange={e => setRating(Number(e.target.value))}
              placeholder='Rating'
              onInput={e => {
                e.target.value = e.target.value.slice(0, 1)
              }}
            />
            <Form.Label className='mt-2'>Break From</Form.Label>
            <Form.Control
              type='text'
              value={divide}
              onChange={e => setDivide(e.target.value)}
              placeholder='Break From'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label className='mt-2'>Add Description</Form.Label>

            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />

            <Form.Group style={{ marginTop: 4 }} className='mb-3'>
              <Form.Label className='mt-2'>Details</Form.Label>
              <br />
              <Form.Label style={{ marginTop: 12 }}>Course Img URL</Form.Label> <br />
              {Img.length === 0 ? (
                <input onChange={UploadImage} style={{ marginTop: 4 }} type='file' placeholder='Course Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }}>Course Hero Img</Form.Label> <br />
              {HeroImg?.length === 0 ? (
                <input onChange={UploadImages} style={{ marginTop: 4 }} type='file' placeholder='Course Img URL' />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setHeroImg('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Curriculum PDF{' '}
              </Form.Label>{' '}
              <br />
              {courseCurriculum?.length === 0 ? (
                <input
                  onChange={UploadCourseCurriculum}
                  style={{ marginTop: 4 }}
                  type='file'
                  placeholder='Course Img URL'
                />
              ) : (
                <>
                  <p className='btn btn-success'>Curriculum Added</p>
                  <p
                    onClick={() => {
                      setCourseCurriculum('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Course Overview Thumbnail
              </Form.Label>{' '}
              <br />
              {courseOverviewThumb?.length === 0 ? (
                <input
                  onChange={UploadImagesH}
                  style={{ marginTop: 4 }}
                  type='file'
                  placeholder='Course Overview URL'
                />
              ) : (
                <>
                  <p className='btn btn-success'>Image Added</p>
                  <p
                    onClick={() => {
                      setcourseOverviewThumb('')
                    }}
                    style={{ cursor: 'pointer' }}
                    className='btn btn-danger'
                  >
                    Replace
                  </p>
                </>
              )}
              <br />
              {/* <Form.Label style={{ marginTop: 12 }}>Coach List</Form.Label>
              <Form.Select
                value={selectCoach}
                onChange={e => setSelectCoach(e.target.value)}
                aria-label='Default select example'
              >
                <option>Open this select coach</option>
                {staffList &&
                  staffList.map(i => {
                    return <option value={i._id}>{i.first_name + ' ' + i.last_name}</option>
                  })}
              </Form.Select> */}
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Course Certification (Diploma or Fellowship)
              </Form.Label>
              <Form.Control
                value={certification}
                onChange={e => setCertificationType(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Certification Type'
              />
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Clinical Quiz
              </Form.Label>
              <Form.Control
                value={certification}
                onChange={e => setCertificationType(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Certification Type'
              />
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Download Course Notes
              </Form.Label>
              <Form.Control
                value={notes}
                onChange={e => setNumberOfNotes(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Total Number of Notes'
              />
              {/* <Form.Label style={{ marginTop: 12 }}>Course Overview Website (Vdocipher Video Link) </Form.Label>
              <Form.Control
                value={overview}
                onChange={e => setOverview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Website Overview Vdocipher URL'
              />
              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Course Overview (Vdocipher Video ID)
              </Form.Label>
              <Form.Control
                value={awsoverview}
                onChange={e => setAwsOverview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Overview (Vdocipher Video Id)'
              /> */}
              <Form.Label style={{ marginTop: 12 }}> Course Overview (Vdocipher Video ID)</Form.Label>
              <Form.Control
                value={perview}
                onChange={e => setPreview(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Vdocipher Video Id'
              />
              {/* <Form.Label style={{ marginTop: 12 }}>Tutor Name</Form.Label>
              <Form.Control
                value={tutorName}
                onChange={e => setTutorName(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Name'
              />
              <Form.Label style={{ marginTop: 12 }}>Tutor Img</Form.Label>
              <Form.Control
                value={tutorImg}
                onChange={e => setTutorImg(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Img URL'
              />
              <Form.Label style={{ marginTop: 12 }}>Tutor Bio</Form.Label>
              <Form.Control
                value={tutorBio}
                onChange={e => setTutorBio(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Tutor Bio'
              /> */}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='mt-2'> Course Mains ( YES / NO )</Form.Label>
              <br />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Published
              </Form.Label>
              <Form.Control
                value={published}
                onChange={e => setPublished(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Published (YES / NO )'
              />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Category
              </Form.Label>
              <Form.Control
                value={category}
                onChange={e => setCategory(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              {/* <Form.Label style={{ marginTop: 12 }}>Summary of Clinical Points to learn</Form.Label>
              <Form.Control
                value={clinicalPoints}
                onChange={e => setclinicalPoints(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Summary of Clinical Points to learn'
              /> */}

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Badge Honor (Yes/No)
              </Form.Label>
              <Form.Control
                value={BadgeHonor}
                onChange={e => setBadgeHonor(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Badge Honor (Yes/No)'
              />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Live with Dr. Krutik Shah{' '}
              </Form.Label>
              <Form.Control
                value={liveShah}
                onChange={e => setliveShah(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Live with Dr. Krutik Shah'
              />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Course Access
              </Form.Label>
              <Form.Control
                value={CourseAccess}
                onChange={e => setCourseAccess(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              {/* <Form.Label style={{ marginTop: 12 }}>Chat with Coach</Form.Label>
              <Form.Control
                value={ChatwithCoach}
                onChange={e => setChatwithCoach(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              /> */}

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                TotalDuration
              </Form.Label>
              <Form.Control
                value={TotalDuration}
                onChange={e => setTotalDuration(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Language
              </Form.Label>
              <Form.Control
                value={Language}
                onChange={e => setLanguage(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }} className='mt-2'>
                Skill Level
              </Form.Label>
              <Form.Control
                value={SkillLevel}
                onChange={e => setSkillLevel(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Category'
              />

              <Form.Label style={{ marginTop: 12 }}>Course Position</Form.Label>
              <Form.Control
                value={serial}
                onChange={e => setSerial(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Course Position for example 1'
              />
              <Form.Label style={{ marginTop: 12 }}>Course Published</Form.Label>
              <Form.Select
                value={coursePublished}
                onChange={e => setCoursePublished(e.target.value)}
                aria-label='Default select example'
              >
                <option value={'publish'}>Published</option>
                <option value={'draft'}>Draft</option>
              </Form.Select>
            </Form.Group>

            <button className='btn btn-info' type='submit'>
              {' '}
              Update Course
            </button>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}
