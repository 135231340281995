import React, { Suspense, useEffect, useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from '../routes'

// pages
import Orders from './Orders'
import Transactions from './Transactions'
import DashboardOverview from './dashboard/DashboardOverview'

// components
import axios from 'axios'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Preloader from '../components/Preloader'
import Sidebar from '../components/Sidebar'
import AllChapters from './AllChapters'
import AllCoach from './AllCoach'
import AllCourse from './AllCourse'
import AllFreeChapters from './AllFreeChapters'
import AllFreeLessons from './AllFreeLessons'
import AllLessons from './AllLessons'
import AllNewsLetter from './AllNewsLetter'
import AllQuiz from './AllQuiz'
import AllStudents from './AllStudents'
import AllTypoFormList from './AllTypoFormList'
import AnswerList from './AnswerList'
import Chat from './Chat'
import Comments from './Comments'
import Coupon from './Coupon'
import FEEDBACK from './Feed-back'
import FreeWebinar from './FreeWebinar'
import { InAppPurchase } from './InAppPurchase'
import InAppPurchaseUser from './InAppPurchaseUser'
import Logger from './Logger'
import Notification from './Notification'
import PaidWebinar from './PaidWebinar'
import QuestionAnswers from './Question-Answers'
import QuizScore from './QuizScore'
import Scholarship from './Scholarship'
import TransactionReportList from './TransactionReportList'
import UserReportList from './UserReportList'
import ComingSoon from './components/ComingSoon'
import { CrateInAppPurchaseCode } from './components/CrateInAppPurchaseCode'
import CreateChapter from './components/CreateChapter'
import CreateCoach from './components/CreateCoach'
import CreateCourse from './components/CreateCourse'
import CreateFreeChapter from './components/CreateFreeChapter'
import CreateFreeCourse from './components/CreateFreeCourse'
import CreateFreeLesson from './components/CreateFreeLesson'
import CreateLesson from './components/CreateLesson'
import CreateQuiz from './components/CreateQuiz'
import CreateTypoForm from './components/CreateTypoForm'
import EditChapter from './components/EditChapter'
import EditCoach from './components/EditCoach'
import EditCourse from './components/EditCourse'
import EditFreeCourse from './components/EditFreeCourse'
import EditFreeLesson from './components/EditFreeLesson'
import { EditInAppPurchase } from './components/EditInAppPurchase'
import EditLesson from './components/EditLesson'
import EditQuiz from './components/EditQuiz'
import EditTypoForm from './components/EditTypoForm'
import ViewAnswer from './components/ViewAnswer'
import ViewFreeCourse from './components/ViewFreeCourse'
import ViewFreeWebinar from './components/ViewFreeWebinar'
import ViewInAppPurcahseUsers from './components/ViewInAppPurcahseUsers'
import { ViewInAppPurchase } from './components/ViewInAppPurchase'
import ViewPaidWebinar from './components/ViewPaidWebinar'
import ViewQuestions from './components/ViewQuestions'
import ViewReportUser from './components/ViewReportUser'
import ViewScholarship from './components/ViewScholarship'
import ViewStudent from './components/ViewStudent'
import Webinar from './components/Webinar'
import AddCoordinator from './components/coordinator-form/add-coordinator'
import EditCoordinator from './components/coordinator-form/edit-coordinator'
import AddUniversity from './components/university-form/add-university'
import EditUniversity from './components/university-form/edit-university'
import AllUniversity from './university/all-university'
import AssignBadges from './university/assign-badges'
import UniversityStudent from './university/assign-course'
import StudentViewPage from './university/assign-course/student-view'
import Coordinator from './university/coordinator'
import Dashboard from './university/dashboard'
import UniversityFacultyCourse from './university/faculty'
import FreeCourse from './university/free-course'
import FreeAllQuiz from './university/free-course/all-quiz'
import CreateFreeQuiz from './university/free-course/create-quiz'
import FreeEditQuiz from './university/free-course/edit-quiz'
import PromoteStudentList from './university/promote-student'
import UniversityFreeQuizCourse from './university/university-quiz'
import UniversityReport from './university/university-report'
import AllCollege from './university/college/all-college'
import EditFreeChapter from './components/EditFreeChapter'
import FreeCourseFeedback from './university/feed-back-free-course'

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT

// const RouteWithLoader = ({ component: Component, ...rest }) => {
//   const [loaded, setLoaded] = useState(false)

//   useEffect(() => {
//     const timer = setTimeout(() => setLoaded(true), 1000)
//     return () => clearTimeout(timer)
//   }, [])

//   return (
//     <Route
//       {...rest}
//       render={props => (
//         <>
//           {' '}
//           <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
//         </>
//       )}
//     />
//   )
// }

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000)
    return () => clearTimeout(timer)
  }, [])

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible)

  const toggleSettings = () => {
    setShowSettings(!showSettings)
    localStorage.setItem('settingsVisible', !showSettings)
  }

  return (
    <Route
      {...rest}
      render={props => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className='content' style={{ height: '100vh !important' }}>
            <Navbar />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </>
      )}
    />
  )
}

export default () => (
  <Switch>
    <RouteWithSidebar exact path={Routes.Signin.path} component={DashboardOverview} />
    <RouteWithSidebar exact path='/all-courses' component={AllCourse} />
    <RouteWithSidebar exact path='/edit/:id' component={EditCourse} />
    <RouteWithSidebar exact path='/edit-free/:id' component={EditFreeCourse} />
    <RouteWithSidebar exact path='/view-free/:id' component={ViewFreeCourse} />
    <RouteWithSidebar exact path='/edit-typo-from/:id' component={EditTypoForm} />
    <RouteWithSidebar exact path='/view-question-from/:id' component={ViewQuestions} />
    <RouteWithSidebar exact path='/edit-coach/:id' component={EditCoach} />
    <RouteWithSidebar exact path='/user-report-view/:id' component={ViewReportUser} />
    <RouteWithSidebar exact path='/view-answer/:id' component={ViewAnswer} />
    <RouteWithSidebar exact path='/in-app-purchase-user/:id' component={ViewInAppPurcahseUsers} />
    <RouteWithSidebar exact path='/free-webinar' component={FreeWebinar} />
    <RouteWithSidebar exact path='/transactions' component={Transactions} />
    <RouteWithSidebar exact path='/orders' component={Orders} />
    <RouteWithSidebar exact path='/paid-webinar' component={PaidWebinar} />
    <RouteWithSidebar exact path='/notification' component={Notification} />
    <RouteWithSidebar exact path='/view-free-webinar/:id' component={ViewFreeWebinar} />
    <RouteWithSidebar exact path='/view-paid-webinar/:id' component={ViewPaidWebinar} />

    <RouteWithSidebar exact path='/mychapters/:id' component={AllChapters} />
    <RouteWithSidebar exact path='/edit-chapter/:id' component={EditChapter} />

    <RouteWithSidebar exact path='/all-lessons/:id' component={AllLessons} />
    <RouteWithSidebar exact path='/create-lesson/:id' component={CreateLesson} />
    <RouteWithSidebar exact path='/all-free-chapters/:id' component={AllFreeChapters} />
    <RouteWithSidebar exact path='/all-free-lessons/:id' component={AllFreeLessons} />
    <RouteWithSidebar exact path='/create-free-lesson/:id' component={CreateFreeLesson} />
    <RouteWithSidebar exact path='/create-quiz/:id' component={CreateQuiz} />
    <RouteWithSidebar exact path='/edit-quiz/:id/:id2' component={EditQuiz} />

    <RouteWithSidebar exact path='/all-quiz/:id' component={AllQuiz} />

    <RouteWithSidebar exact path='/create-course' component={CreateCourse} />
    <RouteWithSidebar exact path='/create-free-course' component={CreateFreeCourse} />
    <RouteWithSidebar exact path='/create-typo-form' component={CreateTypoForm} />
    <RouteWithSidebar exact path='/create-coach' component={CreateCoach} />
    <RouteWithSidebar exact path='/coupon' component={CreateCourse} />
    <RouteWithSidebar exact path='/create-chapter/:id' component={CreateChapter} />
    <RouteWithSidebar exact path='/create-free-chapter/:id' component={CreateFreeChapter} />
    <RouteWithSidebar exact path='/edit-free-chapter/:id' component={EditFreeChapter} />
    <RouteWithSidebar exact path='/create-in-app-purchase' component={CrateInAppPurchaseCode} />
    <RouteWithSidebar exact path='/Edit-in-app-purchase/:id' component={EditInAppPurchase} />
    <RouteWithSidebar exact path='/view-in-app-purchase/:id' component={ViewInAppPurchase} />
    <RouteWithSidebar exact path='/edit-lesson/:id' component={EditLesson} />
    <RouteWithSidebar exact path='/edit-free-lesson/:id' component={EditFreeLesson} />

    <RouteWithSidebar exact path='/answer/course' component={ComingSoon} />

    <RouteWithSidebar exact path='/tutors' component={ComingSoon} />
    <RouteWithSidebar exact path='/add-new-tutor' component={ComingSoon} />
    <RouteWithSidebar exact path='/remove-tutor' component={ComingSoon} />

    <RouteWithSidebar exact path='/answer/feedbacks' component={ComingSoon} />

    <RouteWithSidebar exact path='/live/webinar' component={Webinar} />
    <RouteWithSidebar exact path='/live/chat' component={ComingSoon} />

    <RouteWithSidebar exact path='/all-students' component={AllStudents} />
    <RouteWithSidebar exact path='/all-typo-form' component={AllTypoFormList} />
    <RouteWithSidebar exact path='/all-news-letter' component={AllNewsLetter} />
    <RouteWithSidebar exact path='/coach-list' component={AllCoach} />
    <RouteWithSidebar exact path='/quiz-score' component={QuizScore} />
    <RouteWithSidebar exact path='/chat' component={Chat} />
    <RouteWithSidebar exact path='/in-app-purchase-user' component={InAppPurchaseUser} />
    <RouteWithSidebar exact path='/all-answer' component={AnswerList} />
    <RouteWithSidebar exact path='/in-app-purchase' component={InAppPurchase} />
    <RouteWithSidebar exact path='/user-report' component={UserReportList} />
    <RouteWithSidebar exact path='/transaction-report' component={TransactionReportList} />
    <RouteWithSidebar exact path='/create-coupon' component={Coupon} />
    <RouteWithSidebar exact path='/shah-logger' component={Logger} />
    <RouteWithSidebar exact path='/feed-back' component={FEEDBACK} />
    <RouteWithSidebar exact path='/question-answers' component={QuestionAnswers} />
    <RouteWithSidebar exact path='/comments' component={Comments} />
    <RouteWithSidebar exact path='/all-students/view/:id' component={ViewStudent} />

    <RouteWithSidebar exact path='/free-course' component={FreeCourse} />
    <RouteWithSidebar exact path='/free-course/create-quiz/:id' component={CreateFreeQuiz} />
    <RouteWithSidebar exact path='/free-course/all-quiz/:id' component={FreeAllQuiz} />
    <RouteWithSidebar exact path='/free-course/edit-quiz/:id/:id2' component={FreeEditQuiz} />

    <RouteWithSidebar exact path='/coordinator' component={Coordinator} />
    <RouteWithSidebar exact path='/create-coordinator' component={AddCoordinator} />
    <RouteWithSidebar exact path='/update-coordinator/:id' component={EditCoordinator} />

    <RouteWithSidebar exact path='/university-report' component={UniversityReport} />
    <RouteWithSidebar exact path='/dashboard' component={Dashboard} />

    <RouteWithSidebar exact path='/assign-course' component={UniversityStudent} />
    <RouteWithSidebar exact path='/promote-student' component={PromoteStudentList} />
    <RouteWithSidebar exact path='/faculty' component={UniversityFacultyCourse} />
    <RouteWithSidebar exact path='/assign-badges' component={AssignBadges} />
    <RouteWithSidebar exact path='/assign-course/student-view/:id' component={StudentViewPage} />
    <RouteWithSidebar exact path='/university' component={AllUniversity} />
    <RouteWithSidebar exact path='/create-university' component={AddUniversity} />
    <RouteWithSidebar exact path='/update-university/:id' component={EditUniversity} />
    <RouteWithSidebar exact path='/university-quiz' component={UniversityFreeQuizCourse} />
    <RouteWithSidebar exact path='/university-college/:id' component={AllCollege} />
    <RouteWithSidebar exact path='/feed-back-free-course' component={FreeCourseFeedback} />

    <RouteWithSidebar exact path='/scholarship' component={Scholarship} />
    <Suspense fallback={<div>Loading...</div>}>
      <RouteWithSidebar exact path='/scholarship-view/:id' component={ViewScholarship} />
    </Suspense>

    {/* pages */}

    {/* <RouteWithSidebar exact path='/transactions' component={Transactions} /> */}
    {/* <RouteWithSidebar exact path={Routes.Logger.path} component={Logger} /> */}
    {/* <RouteWithSidebar exact path={Routes.Coupon.path} component={Coupon} /> */}
    {/* <RouteWithSidebar exact path='/create-coupon' component={Coupon} /> */}
    <Redirect from='/all-chapters' to='/all-courses' />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
)
