import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Form } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import { Button, Modal } from '@themesberg/react-bootstrap'
import { UniversityCollegeTable } from '../../../components/free-course-table'
import toast from 'react-hot-toast'
import { callApi } from '../../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const collegeAddEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/college/add'
}

const collegeListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/college/list'
}

const AllCollege = props => {
  const id = props.match.params.id

  const [showModal, setShowModal] = useState(false)
  const [collegeName, setCollegeName] = useState('')

  const [colleges, setColleges] = useState([])

  const handleOpen = () => {
    setCollegeName('')
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async () => {
    if (collegeName === '') {
      alert('College name cannot be empty')
      return
    }

    const body = {
      college_name: collegeName,
      university_id: id
    }

    await callApi({ uriEndPoint: collegeAddEndPoint, body })
      .then(() => {
        Refresh()
        setShowModal(!showModal)
        toast.success('College created successfully!')
      })
      .catch(e => toast.error(e?.message ?? 'College not added'))

    handleClose()
  }

  async function Refresh() {
    await callApi({ uriEndPoint: collegeListEndPoint, query: { university_id: id } })
      .then(res => {
        if (res?.allCollege) {
          setColleges(res?.allCollege ?? [])
        } else {
          setColleges([])
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    Refresh()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0 w-100'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item href='#/university/'>University</Breadcrumb.Item>
            <Breadcrumb.Item active>University Colleges</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Colleges</h4>
          <div className='d-flex justify-content-between  align-items-center'>
            <p className='mb-0'>SHAH College Management.</p>
            <button className='btn btn-info' onClick={handleOpen}>
              Create College
            </button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create a college</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please enter name of the college :</p>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Control
                required
                type='text'
                placeholder='College Name'
                value={collegeName}
                onChange={e => setCollegeName(e?.target?.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' primary onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='secondary' onClick={handleSubmit} disabled={!collegeName?.length}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <UniversityCollegeTable id={id} recallApi={Refresh} colleges={colleges} />
    </>
  )
}

export default AllCollege
