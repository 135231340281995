/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb } from '@themesberg/react-bootstrap'
import { callApi } from '../utils/api-utils'
import { useSocket } from '../hooks/useSocket'
import S3FileUpload from 'react-s3'
import axios from 'axios'
import moment from 'moment-timezone'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const coachListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/chat/chat/:staff_id'
}

export default () => {
  const config = {
    bucketName: 'chatshah',
    region: 'ap-south-1',
    accessKeyId: 'AKIAZTNEOKHHVJ7GHJBI',
    secretAccessKey: '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'
  }

  const socketReceived = useSocket(process.env.REACT_APP_API_KEY)
  const socketSend = useSocket(process.env.REACT_APP_API_KEY_SOCKET_SEND_MSG)
  const [chatViewData, setChatViewData] = useState({})

  const [chatValue, setChatValue] = useState('')

  const myDivRef = useRef(null)
  const fileInputRef = useRef(null)

  const [hasFocused, setHasFocused] = useState(false)
  const [fileAdd, setFileAdd] = useState(null)

  const [replyMsgData, setReplyMsgData] = useState(null)

  const handleFileSelect = () => {
    if (fileAdd) {
      // If a file is already selected, and the user clicks the paperclip icon,
      // clear the selected file.
      setFileAdd(null)

      // Also, reset the file input value to allow selecting the same file again.
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    } else {
      // If no file is selected, trigger the file input by clicking it.
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }
  }

  const handleFileChange = e => {
    const selectedFile = e.target.files[0]

    setFileAdd(selectedFile)
  }

  const [chatArray, setChatArray] = useState([])

  const viewChat = i => {
    setChatViewData(chatArray[i])
  }

  const userData = localStorage.getItem('loginData')
  const userParseData = JSON.parse(userData)
  const sendMsg = async () => {
    if (replyMsgData) {
      const body = {
        student_id: chatViewData?.student_id?._id,
        staff_id: userParseData.id,
        messages: [
          {
            sender_id: userParseData.id,
            reply_sender_id: replyMsgData.sender_id,
            message: !replyMsgData?.reply_msg ? replyMsgData.message : replyMsgData?.reply_msg,
            file: replyMsgData.file,
            reply_id: replyMsgData.sender_id,
            reply_msg: chatValue,
            createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
          }
        ]
      }

      await chatApiFunction(body)

      setReplyMsgData(null)
      setChatValue('')
    } else {
      if (fileAdd) {
        S3FileUpload.uploadFile(fileAdd, config).then(async data => {
          const body = {
            student_id: chatViewData?.student_id?._id,
            staff_id: userParseData.id,
            messages: [
              {
                sender_id: userParseData.id,
                message: chatValue,
                file: data.location,
                reply_id: null,
                reply_sender_id: null,
                reply_msg: null,
                createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          }
          await chatApiFunction(body)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          setFileAdd(null)
          setChatValue('')
        })
      } else {
        if (chatValue) {
          const body = {
            student_id: chatViewData?.student_id?._id,
            staff_id: userParseData.id,
            messages: [
              {
                sender_id: userParseData.id,
                message: chatValue,
                file: null,
                reply_id: null,
                reply_msg: null,
                reply_sender_id: null,
                createdAt: moment().format('YYYY-MM-DD HH:mm:ss')
              }
            ]
          }

          await chatApiFunction(body)
          setFileAdd(null)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }

          setChatValue('')
        }
      }
    }
  }

  const callApiFunction = async () => {
    await callApi({ uriEndPoint: coachListEndPoint, pathParams: { staff_id: userParseData.id } })
      .then(res => {
        const reverseMap = res.data.map(i => {
          i.messages = i.messages.reverse()
          return i
        })

        setChatArray(reverseMap)
      })
      .catch(() => {})
  }

  const connectFrontEndSocket = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_KEY_SOCKET_SEND_MSG}/api/order/buy-course-list`)
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const scrollToBottom = () => {
    if (!hasFocused) {
      // Focus on the element without scrolling if it's the first time
      if (myDivRef.current) {
        myDivRef.current.focus()
      }
      setHasFocused(true) // Set the flag to indicate that it has focused
    } else {
      if (myDivRef.current) {
        myDivRef.current.scrollTop = myDivRef.current.scrollHeight
        myDivRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  const onsubmit = e => {
    e.preventDefault()
  }

  const chatApiFunction = async body => {
    if (socketSend) {
      socketSend.emit('admin_event', body)
    }
    let newMessage = body.messages

    setChatArray(pre => {
      const newChange = pre.map(i => {
        if (i.student_id?._id === chatViewData?.student_id?._id) {
          i.messages = [...newMessage, ...i.messages]

          return i
        } else {
          return i
        }
      })

      return newChange
    })
    scrollToBottom()
  }

  const messageReply = async data => {
    setReplyMsgData(data)
  }

  useEffect(() => {
    callApiFunction()
  }, [])

  useEffect(() => {
    if (socketReceived) {
      socketReceived?.on('admin_panel', async message => {
        setChatArray(pre => {
          const newChange = pre.map(i => {
            if (i?.student_id?._id === message.student_id) {
              if (i.staff_id?._id === message?.staff_id) {
                i.messages = [...message.messages, ...i.messages]

                return i
              }
              return i
            } else {
              return i
            }
          })

          return newChange
        })
        scrollToBottom()
      })
    }
  }, [socketReceived])

  useEffect(() => {
    connectFrontEndSocket()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Chat</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Chat</h4>
          <p className='mb-0'>SHAH Chat management.</p>
        </div>
      </div>
      <div className='chat-container'>
        <div className='sidebar-1'>
          <div className='user-profile'>
            <h3 style={{ color: 'white' }}>Your Chats</h3>
          </div>
          <ul className='contact-list'>
            {chatArray.map((i, index) => {
              return (
                <li
                  className='contact'
                  style={{
                    backgroundColor: i._id === chatViewData._id ? ' #2e3650' : '',
                    color: 'white'
                  }}
                  key={i._id}
                  onClick={() => viewChat(index)}
                >
                  {i?.student_id?.legal_name?.toLocaleUpperCase() || i?.first_name?.toLocaleUpperCase()}
                </li>
              )
            })}
          </ul>
        </div>
        <div className='chat' style={{ display: 'flex', flexDirection: 'column', minHeight: '600px' }}>
          {Object.values(chatViewData).length !== 0 && (
            <div className='chat-header'>
              <h3>
                {chatViewData?.student_id?.legal_name?.toLocaleUpperCase() ||
                  chatViewData?.first_name?.toLocaleUpperCase()}
              </h3>
            </div>
          )}

          {Object.values(chatViewData).length !== 0 && (
            <div
              className='chat-messages'
              style={{
                minHeight: !fileAdd && !replyMsgData ? '485px' : fileAdd && replyMsgData ? '385px' : '432px',
                maxHeight: !fileAdd && !replyMsgData ? '485px' : fileAdd && replyMsgData ? '385px' : '432px'
              }}
              ref={myDivRef}
            >
              {chatViewData?.messages?.map(i => {
                if (i?.file && i?.reply_msg) {
                  return (
                    <div
                      className={chatViewData.staff_id._id === i?.sender_id ? 'message sent' : 'message received'}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '0'
                      }}
                    >
                      <div
                        key={i._id}
                        className={chatViewData.staff_id._id === i?.sender_id ? 'message sent' : 'message received'}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                          borderLeft: i?.sender_id === chatViewData?.staff_id?._id ? '5px solid red' : '5px solid blue',
                          minWidth: '100px',
                          margin: '0'
                        }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px' }}>
                          {i?.sender_id === chatViewData?.staff_id?._id
                            ? 'You'
                            : chatViewData.student_id.legal_name.toLocaleUpperCase()}
                        </p>
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                          {i?.message ? i?.message : i?.file?.split('/')[3]}
                        </p>
                      </div>
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                        {i?.reply_msg ? i?.reply_msg : null}
                      </p>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignSelf: 'flex-end'
                        }}
                      >
                        {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                      </p>
                    </div>
                  )
                }
                if (!i?.file && i?.reply_msg) {
                  return (
                    <div
                      className={chatViewData.staff_id._id === i?.sender_id ? 'message sent' : 'message received'}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '0',
                        marginTop: '15px'
                      }}
                    >
                      <div
                        key={i._id}
                        className={chatViewData.staff_id._id === i?.sender_id ? 'message sent' : 'message received'}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                          borderLeft: i?.sender_id === chatViewData?.staff_id?._id ? '5px solid red' : '5px solid blue',
                          minWidth: '100px',
                          margin: '0'
                        }}
                        onDoubleClick={() => messageReply(i)}
                      >
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '12px' }}>
                          {i?.reply_sender_id === chatViewData?.staff_id?._id
                            ? 'You'
                            : chatViewData.student_id.legal_name.toLocaleUpperCase()}
                        </p>
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                          {i?.reply_msg ? i?.message : null}
                        </p>
                      </div>
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', fontSize: '16px' }}>
                        {i?.reply_msg ? i?.reply_msg : null}
                      </p>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignSelf: 'flex-end'
                        }}
                      >
                        {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                      </p>
                    </div>
                  )
                }
                if (!i?.file && i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={chatViewData.staff_id._id === i?.sender_id ? 'message sent' : 'message received'}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: '100px',
                        alignItems: 'start',
                        marginTop: '15px'
                      }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {i?.message ? i.message : i.message}
                      </p>
                      <p
                        className='sent-p'
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '0',
                          fontSize: '8px',
                          alignSelf: 'flex-end'
                        }}
                      >
                        {i?.createdAt ? moment(i.createdAt).format('HH:mm') : i.createdAt}
                      </p>
                    </div>
                  )
                }
                if (i?.file && i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={
                        chatViewData.staff_id._id === i?.sender_id ? 'message sent-pdf' : 'message received-pdf'
                      }
                      style={{ display: 'flex', flexDirection: 'column' }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '2px' }}>
                        {i?.message ? i.message : i.message}
                      </p>

                      <a
                        href={chatViewData.staff_id._id !== i?.sender_id ? null : i?.file}
                        target={chatViewData.staff_id._id !== i?.sender_id ? '_self' : '_blank'}
                        rel='noreferrer'
                      >
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                          <i className='fa-solid fa-file-pdf' style={{ fontSize: '25px', color: 'red' }}></i>
                          {i?.file ? i?.file?.split('/')[3] : i.file}
                          {chatViewData.staff_id._id !== i?.sender_id && (
                            <a href={i?.file} download rel='noreferrer' style={{ color: 'green' }}>
                              <i className='fa-solid fa-download' style={{ fontSize: '15px', marginLeft: '10px' }}></i>
                            </a>
                          )}
                        </p>
                        <p
                          className='sent-p'
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            margin: '0',
                            fontSize: '8px',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                        </p>
                      </a>
                    </div>
                  )
                }
                if (i?.file && !i?.message) {
                  return (
                    <div
                      key={i._id}
                      className={
                        chatViewData.staff_id._id === i?.sender_id ? 'message sent-pdf' : 'message received-pdf'
                      }
                      style={{ display: 'flex', flexDirection: 'column' }}
                      onDoubleClick={() => messageReply(i)}
                    >
                      <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '2px' }}>
                        {i?.message ? i.message : i.message}
                      </p>

                      <a
                        href={chatViewData.staff_id._id !== i?.sender_id ? null : i?.file}
                        target={chatViewData.staff_id._id !== i?.sender_id ? '_self' : '_blank'}
                        rel='noreferrer'
                      >
                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                          <i className='fa-solid fa-file-pdf' style={{ fontSize: '25px', color: 'red' }}></i>
                          {i?.file ? i?.file?.split('/')[3] : i.file}
                          {chatViewData.staff_id._id !== i?.sender_id && (
                            <a href={i?.file} download rel='noreferrer' style={{ color: 'green' }}>
                              <i className='fa-solid fa-download' style={{ fontSize: '15px', marginLeft: '10px' }}></i>
                            </a>
                          )}
                        </p>
                        <p
                          className='sent-p'
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            margin: '0',
                            fontSize: '8px',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                          }}
                        >
                          {i?.createdAt ? moment(i?.createdAt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm') : null}
                        </p>
                      </a>
                    </div>
                  )
                }
              })}
            </div>
          )}

          {Object.values(chatViewData).length !== 0 && (
            <form onSubmit={onsubmit}>
              <div className='message-input' style={{ position: 'relative' }}>
                <div className='custom-input'>
                  {fileAdd && (
                    <div className='attachment-preview'>
                      <div
                        style={{
                          padding: '9px',

                          position: 'relative',
                          borderRadius: '5px',
                          backgroundColor: '#dcf8c6'
                        }}
                      >
                        <div
                          style={{ position: 'absolute', top: '-10px', right: '-5px', cursor: 'pointer' }}
                          onClick={() => {
                            setFileAdd(null)
                            if (fileInputRef.current) {
                              fileInputRef.current.value = ''
                            }
                          }}
                        >
                          <i className='fa-solid fa-circle-xmark'></i>
                        </div>

                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                          <i className='fa-solid fa-file-pdf' style={{ fontSize: '25px', color: 'red' }}></i>

                          <div>{fileAdd?.name}</div>
                        </p>
                      </div>
                    </div>
                  )}

                  {replyMsgData && (
                    <div className='replay-preview' style={{ transform: 'translate-middle' }}>
                      <div
                        style={{
                          padding: '9px',

                          position: 'relative',
                          borderRadius: '5px',
                          backgroundColor: '#dcf8c6',
                          width: '100%',
                          borderLeft: '5px solid  #252b40'
                        }}
                      >
                        <div
                          style={{ position: 'absolute', top: '-10px', right: '-5px', cursor: 'pointer' }}
                          onClick={() => {
                            setReplyMsgData(null)
                          }}
                        >
                          <i className='fa-solid fa-circle-xmark'></i>
                        </div>

                        <p className='sent-p' style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
                          <div
                            style={{
                              fontSize: '12px',
                              color: replyMsgData?.sender_id !== chatViewData?.staff_id?._id ? 'blue' : 'orangered'
                            }}
                          >
                            {replyMsgData?.sender_id !== chatViewData?.staff_id?._id
                              ? chatViewData.student_id.legal_name.toLocaleUpperCase()
                              : 'You'}
                          </div>
                          <div style={{ fontSize: '10px' }}>
                            {replyMsgData?.message ? replyMsgData?.message : replyMsgData.file?.split('/')[3]}
                          </div>
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                  >
                    <input
                      type='text'
                      value={chatValue}
                      placeholder={`Message to ${chatViewData?.student_id?.legal_name}`}
                      onChange={e => setChatValue(e.target.value)}
                    />
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                      <div className='attachment'>
                        <input
                          type='file'
                          ref={fileInputRef || ''}
                          accept='.pdf, .csv,'
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />

                        <i
                          className='fa-solid fa-paperclip'
                          onClick={handleFileSelect}
                          style={{ cursor: 'pointer' }}
                          htmlFor='image'
                        ></i>
                      </div>
                      <button onClick={() => sendMsg()} type='submit'>
                        <i className='fa-regular fa-paper-plane'></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          {Object.values(chatViewData).length === 0 && <div className='no-data-found'>No Data Found</div>}
        </div>
      </div>
    </>
  )
}
