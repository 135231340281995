import S3 from 'aws-sdk/clients/s3'

export const awsDownload = async (filePath, duration = 60) => {
  try {
    const region = process.env.REACT_APP_CLOUD_REGION
    const endpoint = process.env.REACT_APP_CLOUD_ENDPOINT
    const accessKeyId = process.env.REACT_APP_CLOUD_ACCESS_KEY
    const secretAccessKey = process.env.REACT_APP_CLOUD_SECRET_KEY

    const client = new S3({
      region,
      endpoint,
      accessKeyId,
      secretAccessKey,
      signatureVersion: 'v4' // Explicitly set the signature version
    })

    const url = await client.getSignedUrlPromise('getObject', {
      Bucket: process.env.REACT_APP_CLOUD_BUCKET_ID,
      Key: filePath,
      Expires: duration
    })
    return url
  } catch (error) {
    console.error('Error generating signed URL:', error)
    throw error
  }
}
