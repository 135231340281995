import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faPassport } from '@fortawesome/free-solid-svg-icons'
import {
  // Row,
  // Col,
  Nav,
  // Form,
  // Image,
  Navbar,
  Dropdown,
  Container,
  ListGroup,
  Image
  // ListGroup
  // InputGroup
} from '@themesberg/react-bootstrap'
import { useUserContext } from '../context/MyContext'
import { callApi } from '../utils/api-utils'

// import { faBell } from '@fortawesome/free-solid-svg-icons'

// import NOTIFICATIONS_DATA from '../data/notifications'
// import { app } from '../config/firebase'
import Profile4 from '../assets/img/team/profile-picture-4.jpg'

function Logout() {
  localStorage.removeItem('loginData')
  window.location.herf = '/'
  window.location.reload()
}

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const coachPasswordUpdateEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/staff/update-password/:id'
}

export default () => {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA)
  // const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true)

  // const markNotificationsAsRead = () => {
  //   setTimeout(() => {
  //     // setNotifications(notifications.map(n => ({ ...n, read: true })))
  //   }, 300)
  // }

  // const Notification = props => {
  //   const { link, sender, image, time, message, read = false } = props
  //   const readClassName = read ? '' : 'text-danger'

  //   return (
  //     <ListGroup.Item action href={link} className='border-bottom border-light'>
  //       <Row className='align-items-center'>
  //         <Col className='col-auto'>
  //           <Image src={image} className='user-avatar lg-avatar rounded-circle' />
  //         </Col>
  //         <Col className='ps-0 ms--2'>
  //           <div className='d-flex justify-content-between align-items-center'>
  //             <div>
  //               <h4 className='h6 mb-0 text-small'>{sender}</h4>
  //             </div>
  //             <div className='text-end'>
  //               <small className={readClassName}>{time}</small>
  //             </div>
  //           </div>
  //           <p className='font-small mt-1 mb-0'>{message}</p>
  //         </Col>
  //       </Row>
  //     </ListGroup.Item>
  //   )
  // }
  const { user } = useUserContext()
  const [addFiled, setAddFiled] = useState({
    password: '',

    error: ''
  })

  const [showPassword, setShowPassword] = useState(false)
  const [errorHandel, setErrorHandel] = useState(false)
  const [updatePassWordModel, setUpdatePassWordModel] = useState(false)
  const handelOpenPassWordUpdate = () => {
    setUpdatePassWordModel(!updatePassWordModel)
  }

  const handelChange = e => {
    setAddFiled({ ...addFiled, [e.target.name]: e.target.value })
  }

  const cancelPassword = () => {
    setAddFiled({})
  }

  const confirmPasswordClick = async () => {
    delete addFiled.error
    const checkValue = Object.values(addFiled).every(v => v)
    if (checkValue) {
      await callApi({
        uriEndPoint: coachPasswordUpdateEndPoint,
        body: addFiled,
        pathParams: { id: user.id || null }
      })
        .then(res => {
          alert(res?.message || 'Record deleted successfully')

          handelOpenPassWordUpdate()
        })
        .catch(e => console.log(e))
    } else {
      setErrorHandel(true)
    }
  }

  return (
    <Navbar variant='dark' expanded className='ps-0 pe-2 pb-0'>
      <Container fluid className='px-0'>
        <div className='d-flex justify-content-between w-100'>
          <div className='d-flex align-items-center'>
            {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
          </div>
          <Nav className='align-items-center'>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className='text-dark icon-notifications me-lg-3'>
                <Image src={Profile4} className='user-avatar md-avatar rounded-circle' />
              </Dropdown.Toggle>
              <Dropdown.Menu className='dashboard-dropdown notifications-dropdown dropdown-menu-md dropdown-menu-center mt-2 py-0 left-auto'>
                <ListGroup className='list-group-flush'>
                  <Dropdown.Item className='text-center text-primary fw-bold py-3'>
                    {' '}
                    <div className='media d-flex align-items-center'>
                      {/* <Image src={Profile4} className='user-avatar md-avatar rounded-circle' /> */}

                      <div
                        style={{ cursor: 'pointer' }}
                        data-toggle='modal'
                        data-target='#exampleModalCenter-password'
                        className='media-body ms-2 text-dark align-items-center d-none d-lg-block'
                      >
                        <span className='mb-0 font-small fw-bold'>
                          <FontAwesomeIcon icon={faPassport} className='text-danger me-2' /> Update Password
                        </span>
                      </div>
                    </div>
                  </Dropdown.Item>

                  {/* {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)} */}

                  <Dropdown.Item className='text-center text-primary fw-bold py-3'>
                    {' '}
                    <div className='media d-flex align-items-center'>
                      {/* <Image src={Profile4} className='user-avatar md-avatar rounded-circle' /> */}

                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => Logout()}
                        className='media-body ms-2 text-dark align-items-center d-none d-lg-block'
                      >
                        <span className='mb-0 font-small fw-bold'>
                          <FontAwesomeIcon icon={faSignOutAlt} className='text-danger me-2' /> Logout
                        </span>
                      </div>
                    </div>
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
        <div
          className='modal fade'
          id='exampleModalCenter-password'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='exampleModalCenterTitle'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <div className='modal-header  w-100 justify-content-center'>
                <h5 className='modal-title' id='exampleModalLongTitle'>
                  Are you sure? {user?.role || ''}
                </h5>
              </div>

              <div className='row p-4'>
                <div className='form-group col-md-12'>
                  <label htmlFor='password'>Update Password*</label>
                  <div className='d-flex align-items-center position-relative'>
                    <input
                      autoComplete={false}
                      type={showPassword ? 'text' : 'password'}
                      name='password'
                      value={addFiled?.password || ''}
                      onChange={handelChange}
                      className='form-control'
                      id='password'
                      placeholder='Password'
                    />
                    <span
                      className='eye-icon position-absolute '
                      style={{ right: '10px', cursor: 'pointer' }}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <i className='fa-solid fa-eye'></i> : <i className='fa-solid fa-eye-slash'></i>}
                    </span>
                  </div>
                </div>
                {errorHandel && (
                  <h5 style={{ color: 'orangered', textTransform: 'capitalize', marginTop: '10px' }}>
                    * require filed
                  </h5>
                )}
              </div>

              <div className='modal-footer justify-content-center'>
                <button
                  type='button'
                  className='btn btn-secondary'
                  data-dismiss='modal'
                  onClick={() => confirmPasswordClick()}
                >
                  Yes, update it!
                </button>
                <button type='button' className='btn btn-primary' data-dismiss='modal' onClick={() => cancelPassword()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  )
}
