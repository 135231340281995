import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Col, Row, Form, Container } from '@themesberg/react-bootstrap'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import htmlToDraft from 'html-to-draftjs'
import {
  EditorState,
  convertToRaw
  //  ContentState
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'

import axios from 'axios'

const CreateFreeQuiz = props => {
  const [id] = useState(props.match.params.id)

  const [nameOfCourse, setnameOfCourse] = useState('')
  const [quizType, setQuizType] = useState(false)
  const [Img, setImg] = useState('')
  const [RegularPrice, setRegularPrice] = useState('')
  const [SellingPrice, setSellingPrice] = useState('')
  const [tutorName, setTutorName] = useState('')
  const [tutorImg, setTutorImg] = useState('')
  const [tutorBio, setTutorBio] = useState('')
  const [isPaid, setIsPaid] = useState('')
  const [quizNumber, setQuizNumber] = useState(null)

  const [
    ,
    // find
    setFind
  ] = useState(false)

  const [questions, setQuestions] = useState([])

  const [questionsCount, setQuestionsCount] = useState(0)

  useEffect(() => {
    console.log(id)
  }, [])

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const handleEditorChange = state => {
    setEditorState(state)
  }

  function RecieveData(html) {
    const data = '' + html + ''
    console.log(data)

    //  if (data) {
    //   const contentState = ContentState.createFromBlockArray(
    //     data.contentBlocks
    //   )
    //   const editorState = EditorState.createWithContent(contentState);
    //   setEditorState(editorState);

    // }
  }

  function CourseCreation(e) {
    e.preventDefault()
    FindData(id)

    // convertContentToHTML()
    // alert('Quiz Created')
  }

  async function FindData(ids) {
    await axios
      .get(`/api/free-lesson-quiz/view/${ids}`)
      .then(res => {
        setQuestions(res.data.data.questions || [])
        setFind(true)
      })
      .catch(() => {
        setFind(false)
        convertContentToHTML()
      })
  }

  // LoadData()

  // Create Questions and quiz
  // Update Questions
  // Delete Questions
  // Delete Quiz

  useEffect(() => {
    LoadQuiz(id)
  }, [])

  async function SaveQuiz() {
    const q = questions

    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    await axios
      .put(`/api/free-lesson-quiz/update/${id}`, {
        free_lesson_id: id,
        description: currentContentAsHTML,
        time: isPaid,
        showQuiz: quizNumber,
        questions: q
      })
      .then(() => {
        alert('Quiz is updated')
        setImg('')
        setRegularPrice('')
        setSellingPrice('')
        setTutorName('')
        setTutorImg('')
        setTutorBio('')
      })
  }

  async function addNextQuestion() {
    // if (questions.length === 0) {
    //   alert('Please save the quiz afterwards create a new question')
    // } else {
    const q = questions

    const dataQuestion = []

    dataQuestion.push({
      question: Img,
      answer: RegularPrice,
      option1: quizType === true ? 'True' : SellingPrice,
      option2: quizType === true ? 'False' : tutorName,
      option3: tutorImg,
      option4: tutorBio,
      type: quizType
    })

    const c = [...q, ...dataQuestion]
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    await axios
      .put(`/api/free-lesson-quiz/update/${id}`, {
        free_chapter_id: id,
        nameOfCourse: nameOfCourse,
        description: currentContentAsHTML,
        time: isPaid,
        questions: c
      })
      .then(() => {
        alert('New Question Created')
        setImg('')
        setRegularPrice('')
        setSellingPrice('')
        setTutorName('')
        setTutorImg('')
        setTutorBio('')
        setQuestionsCount(questionsCount + 1)
      })
      .catch(e => alert(e))
    // }
  }

  // function RecieveData(html) {
  //   const contentBlock = htmlToDraft(html)
  //   if (contentBlock) {
  //     const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  //     const editorState = EditorState.createWithContent(contentState)
  //     setEditorState(editorState)
  //   }
  // }

  async function LoadQuiz(ids) {
    await axios
      .get(`/api/free-lesson-quiz/view/${ids}`)
      .then(res => {
        setnameOfCourse(res.data?.data?.nameOfCourse)
        setIsPaid(res.data?.data?.time)
        setQuizNumber(res?.data?.data?.showQuiz)
        setQuestions(res.data?.data?.questions)
        setQuestionsCount(res.data?.data?.questions.length)
        RecieveData(res.data?.data?.description)
      })
      .catch(err => console.log(err))
  }

  const convertContentToHTML = () => {
    let currentContentAsHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    const dataQuestion = []

    dataQuestion.push({
      question: Img,
      answer: RegularPrice,
      option1: quizType === true ? 'True' : SellingPrice,
      option2: quizType === true ? 'False' : tutorName,
      option3: tutorImg,
      option4: tutorBio,
      type: quizType
    })

    axios
      .post('/api/free-lesson-quiz/add', {
        free_chapter_id: id,
        nameOfCourse: nameOfCourse,
        description: currentContentAsHTML,
        time: isPaid,

        questions: dataQuestion
      })
      .then(() => {
        alert('Quiz Created')
        window.location.reload()
      })
      .catch(e => alert(e))
  }

  return (
    <article>
      <Container className='px-0'>
        <Row className='d-flex flex-wrap flex-md-nowrap align-items-center py-4'>
          <Col className='d-block mb-4 mb-md-0'>
            <h1 className='h2' onClick={() => window.history.back()}>
              {' '}
              <FontAwesomeIcon icon={faArrowLeft} style={{ cursor: 'pointer' }} /> Create Quiz
            </h1>
            <p className='mb-0'>Here you can create a quiz for SHAH</p>
          </Col>
        </Row>
        <Form onSubmit={CourseCreation}>
          <Form.Group className='mb-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              value={nameOfCourse}
              onChange={e => setnameOfCourse(e.target.value)}
              placeholder='Write your Quiz Title'
            />

            <Form.Label style={{ marginTop: 12 }}> Quiz time (min)</Form.Label>
            <Form.Control
              value={isPaid}
              onChange={e => setIsPaid(e.target.value)}
              style={{ marginTop: 4 }}
              type='text'
              placeholder='Quiz time in min . Example 60min = 1hr'
            />
            <Form.Label style={{ marginTop: 12 }}>How many quiz do you want to show to the user?</Form.Label>
            <Form.Control
              value={quizNumber}
              onChange={e => setQuizNumber(e.target.value)}
              maxLength={questionsCount}
              onInput={e => {
                if (Number(e.target.value) <= questionsCount) {
                  e.target.value = Number(e.target.value)
                } else {
                  e.target.value = questionsCount
                }
              }}
              style={{ marginTop: 4 }}
              type='text'
              placeholder='Example 5'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Add Description</Form.Label>

            <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              wrapperClassName='demo-wrapper'
              editorClassName='demo-editor'
              toolbarClassName='toolbar-class'
            />

            <Form.Group style={{ marginTop: 12 }} className='mb-3'>
              <Form.Label>Create Question</Form.Label>
              <Form.Control
                value={Img}
                as='textarea'
                onChange={e => setImg(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Create Question'
              />

              <Form.Label style={{ marginTop: 12 }}>Choose question type</Form.Label>
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div
                  onClick={() => {
                    quizType === false ? setQuizType(true) : setQuizType(true)
                  }}
                  style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
                >
                  {' '}
                  <input checked={quizType} type='checkbox' /> True/ False
                </div>
                <div
                  onClick={() => {
                    quizType === true
                      ? setQuizType(false)
                      : setQuizType(false) && setSellingPrice('') && setTutorName('')
                  }}
                  style={{ marginLeft: 5, marginRight: 5, cursor: 'pointer' }}
                >
                  <input checked={quizType === false ? true : false} type='checkbox' /> MCQs
                </div>
              </div>

              <Form.Label style={{ marginTop: 12 }}>Create Options</Form.Label>
              <Form.Control
                value={quizType === true ? 'True' : SellingPrice}
                onChange={e => setSellingPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder={quizType === true ? 'True' : 'Option 1'}
              />
              <Form.Control
                value={quizType === true ? 'False' : tutorName}
                onChange={e => setTutorName(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder={quizType === true ? 'False' : 'Option 2'}
              />

              {quizType === false ? (
                <>
                  <Form.Control
                    value={tutorImg}
                    onChange={e => setTutorImg(e.target.value)}
                    style={{ marginTop: 4 }}
                    type='text'
                    placeholder='Option 3'
                  />
                  <Form.Control
                    value={tutorBio}
                    onChange={e => setTutorBio(e.target.value)}
                    style={{ marginTop: 4 }}
                    type='text'
                    placeholder='Option 4'
                  />
                </>
              ) : (
                <></>
              )}

              <Form.Label style={{ marginTop: 12 }}>Correct Answer</Form.Label>
              <Form.Control
                value={RegularPrice}
                onChange={e => setRegularPrice(e.target.value)}
                style={{ marginTop: 4 }}
                type='text'
                placeholder='Write your answer'
              />
            </Form.Group>

            <button
              style={{ cursor: 'pointer', marginRight: 12 }}
              onClick={() => SaveQuiz()}
              type='submit'
              className='btn btn-info'
            >
              Save Quiz
            </button>
            <button onClick={() => addNextQuestion()} style={{ cursor: 'pointer' }} className='btn btn-danger'>
              Add Next Question{' '}
            </button>
            <div
              onClick={() => (window.location.href = `#/free-course/all-quiz/${id}`)}
              style={{ cursor: 'pointer', marginLeft: 12 }}
              className='btn btn-success'
            >
              View Questions {questionsCount === 0 ? <></> : questionsCount?.toString()}
            </div>
          </Form.Group>
        </Form>
      </Container>
    </article>
  )
}

export default CreateFreeQuiz
