import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Button, Col, Form, Modal, Row } from '@themesberg/react-bootstrap'
import React, { useEffect, useState } from 'react'

import toast from 'react-hot-toast'
import { PromoteStudentTable } from '../../../components/free-course-table'
import { callApi } from '../../../utils/api-utils'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}

const universityListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/university/list'
}

const studentListEndPoint = {
  ...defaults.methods.GET,
  uri: '/api/free-course-user/list'
}

const promoteStudent = {
  ...defaults.methods.PATCH,
  uri: '/api/free-course-user/promote-student'
}

const studentApproveStatusUpdate = {
  ...defaults.methods.POST,
  uri: '/api/free-course-user/update-approval-status'
}

const academicYearData = [
  { u_id: 1, value: '1', label: '1st year' },
  { u_id: 2, value: '2', label: '2nd year' },
  { u_id: 3, value: '3', label: '3rd year' },
  { u_id: 4, value: '4', label: '4th year' },
  { u_id: 5, value: '5', label: 'Internship' },
  { u_id: 6, value: '6', label: 'MD Part 1' },
  { u_id: 7, value: '7', label: 'MD Part 2' }
]
const academicYearArchiveData = [
  { u_id: 1, value: '1', label: '1st year' },
  { u_id: 2, value: '2', label: '2nd year' },
  { u_id: 3, value: '3', label: '3rd year' },
  { u_id: 4, value: '4', label: '4th year' },
  { u_id: 5, value: '5', label: 'Internship' },
  { u_id: 6, value: '6', label: 'MD Part 1' },
  { u_id: 7, value: '7', label: 'MD Part 2' },
  { u_id: 8, value: 'archive', label: 'ARCHIVE' }
]

const PromoteStudentList = props => {
  // ** id
  const id = props.match.params.id

  // ** states
  const [universityList, setUniversityList] = useState(null)
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [academicYear, setAcademicYear] = useState('active')
  const [promotedYear, setPromotedYear] = useState('')
  const [gender, setGender] = useState('')

  const [showAssignModal, setShowAssignModal] = useState(false)
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
  const [selectedCourses, setSelectedCourses] = useState([])
  const [selectedStudentIds, setSelectedStudentIds] = useState([])

  const [studentList, setStudentList] = useState([])
  const [recallApi, setRecallApi] = useState('')
  const [selectedApproveStatus, setSelectedApproveStatus] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(100) // Default to 10 items per page
  const [totalItems, setTotalItems] = useState(0)

  const handleClose = () => setShowModal(false)

  async function approveStatus() {
    // First, filter the students whose approval status is NOT 'approve'
    const studentsToUpdate = studentList.filter(
      student => selectedStudentIds.includes(student._id) && student.approval_status !== selectedApproveStatus
    )

    // Extract the IDs of those students who need to be updated
    const idsToUpdate = studentsToUpdate.map(student => student._id)

    // If no students need to be updated, do not proceed
    if (idsToUpdate.length === 0) {
      toast.error('No students need approval status update.')
      return
    }

    let body = {
      approval_status: selectedApproveStatus,
      students: idsToUpdate
    }

    try {
      // Send the API request
      await callApi({ uriEndPoint: studentApproveStatusUpdate, body })

      // Refresh the data and reset states
      setRecallApi(Math.floor(Math.random(1) * 100).toString())
      setShowModal(false)
      setSelectedApproveStatus(null)
      setSelectedStudentIds([])
    } catch (e) {
      console.error('Error updating approval status:', e)
    }
  }

  const handelChangeApprove = e => {
    // Update form field value
    setSelectedApproveStatus(e.target.value)
  }

  // ** common student list

  async function StudentListData(page = currentPage, itemsPerPage = 100) {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        pursuing_year: academicYear,
        gender,
        page, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  const handlePageChange = newPage => {
    if (newPage >= 1 && newPage <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(newPage) // Update current page
    }
  }

  const handleItemsPerPageChange = event => {
    setItemsPerPage(Number(event.target.value)) // Update the number of items per page
    setCurrentPage(1) // Reset to the first page when items per page is changed
  }

  useEffect(() => {
    StudentListData(currentPage, itemsPerPage)
  }, [recallApi, currentPage, itemsPerPage])

  // ** common pursuing year
  // Add event handlers for new filters
  const handleAcademicYearChange = e => {
    setAcademicYear(e.target.value)
  }
  const handlePromotesYearChange = e => {
    setPromotedYear(e.target.value)
  }

  const handleGenderChange = e => {
    setGender(e.target.value)
  }

  const handleAssignShow = () => {
    !!selectedStudentIds?.length && setShowAssignModal(true)
  }
  const handleAssignClose = () => {
    setShowAssignModal(false)

    setSelectedCourses([])
  }

  // Handler for "Assign Course" button click
  const handleAssign = async () => {
    // Check if there are any students selected
    if (selectedStudentIds.length === 0) {
      toast.error('No students selected.')
      return
    }

    // Show the Disclaimer Modal for confirmation
    setShowAssignModal(false) // Close the first modal
    setShowDisclaimerModal(true) // Open the disclaimer modal
  }

  // Final confirmation to assign courses after disclaimer
  const handleConfirmAssign = async () => {
    // Proceed with course assignment
    const body = {
      students: selectedStudentIds,
      promoted_year: promotedYear
    }

    try {
      // Call API for course assignment (assuming `promoteStudent` endpoint exists)
      const res = await callApi({
        uriEndPoint: promoteStudent,
        body
      })

      if (res) {
        toast.success('Student Promoted successfully!')
        // Reset states and close modals
        setSelectedStudentIds([])
        setSelectedCourses([])
        setShowDisclaimerModal(false)
      }
    } catch (error) {
      toast.error(error?.message ?? 'Error occurred while promoting student.')
    }
  }

  //   const handleAssign = async () => {
  //     const studentsToUpdate = studentList.filter(
  //       student => selectedStudentIds.includes(student._id) && student.approval_status === 'approve'
  //     )
  //     // Extract the IDs of those students who need to be updated
  //     const idsToUpdate = studentsToUpdate?.map(student => student._id)

  //     // If no students need to be updated, do not proceed
  //     if (idsToUpdate.length === 0) {
  //       // alert('No students with approval status "approve".')
  //       toast.error('No students with approval status "approve".')
  //       return
  //     }

  //     const body = {
  //       // university_id: selectedUniversity,
  //       // pursuing_year: academicYear,
  //       // gender,
  //       courses: selectedCourses,
  //       students: idsToUpdate
  //     }

  //     if (selectedCourses || idsToUpdate) {
  //       try {
  //         const res = await callApi({
  //           uriEndPoint: promoteStudent,
  //           body: body
  //         })

  //         if (res) {
  //           // Reset form fields after successful assignment
  //           setSelectedStudentIds([])
  //           setShowAssignModal(false)

  //           setSelectedCourses([])

  //           toast.success(res?.message ?? 'Courses successfully assigned!')
  //         }
  //       } catch (error) {
  //         toast.error(error?.message ?? 'Error occurred while assigning courses.')
  //       }
  //     }
  //   }

  // Search button handler common
  const handleSearch = async () => {
    await callApi({
      uriEndPoint: studentListEndPoint,
      query: {
        approval_status: 'approve',
        university_id: selectedUniversity === 'all' ? '' : selectedUniversity || ' ',
        pursuing_year: academicYear,
        gender,
        page: currentPage, // Current page
        items_per_page: itemsPerPage // Items per page
      }
    })
      .then(res => {
        if (res?.data) {
          setStudentList(res.data)
          setTotalItems(res?.payload?.pagination?.total)
        }
      })
      .catch(e => console.log(e))
  }

  useEffect(() => {
    callApi({ uriEndPoint: universityListEndPoint, query: { status: true } }).then(res => {
      setUniversityList(res)
    })
  }, [])

  const handelChange = e => {
    setSelectedUniversity(e.target.value)
  }

  const handleDisclaimerClose = () => {
    setShowDisclaimerModal(false)
    // Close the modal without making any changes
    setShowAssignModal(true)
  }

  return (
    <>
      <div className='d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2'>
        <div className='d-block mb-4 mb-md-0'>
          <Breadcrumb
            className='d-none d-md-inline-block'
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>SHAH</Breadcrumb.Item>
            <Breadcrumb.Item active>All Students</Breadcrumb.Item>
          </Breadcrumb>
          <h4>All Student and Promote Student</h4>
        </div>
      </div>
      <div className=''>
        <div className='row gy-3 gx-3 mb-3'>
          <div className='form-group col-md-6 col-lg-4'>
            <label htmlFor='university_id'>Select University</label>
            <select
              id='university_id'
              value={selectedUniversity || ''} // value is controlled by state
              onChange={handelChange}
              name='university_id'
              className='form-control'
              placeholder='Select University'
            >
              {/* Add a default placeholder option */}
              <option value='' disabled>
                Select University
              </option>
              {/* Populate options dynamically */}
              {universityList &&
                universityList?.allUniversity?.map(university => (
                  <option key={university?._id} value={university?._id}>
                    {university?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className='form-group col-md-6 col-lg-3'>
            <label htmlFor='academicYear'>Pursuing Year</label>
            <select id='academicYear' value={academicYear} onChange={handleAcademicYearChange} className='form-control'>
              <option value='active'>All Active</option>
              {/* Populate pursuing year options */}
              {academicYearData?.map(item => (
                <option key={item?.u_id} value={item?.value}>
                  {item?.label}
                </option>
              ))}

              <hr />

              <option value='archive' className='fw-bold'>
                ARCHIVE
              </option>
            </select>
          </div>
          <div className='form-group col-md-6 col-lg-3'>
            <label htmlFor='gender'>Gender</label>
            <select id='gender' value={gender} onChange={handleGenderChange} className='form-control'>
              <option value=''>All</option>
              <option value='male'>Male</option>
              <option value='female'>Female</option>
            </select>
          </div>
          <div className='d-flex align-items-end col-md-6 col-lg-2'>
            <button className='btn btn-primary w-100' onClick={() => handleSearch()}>
              Search
            </button>
          </div>
        </div>
        <div className='mb-3'>
          <button className='btn btn-secondary' onClick={handleAssignShow} disabled={!selectedStudentIds?.length}>
            Promote Student
          </button>
        </div>

        <PromoteStudentTable
          id={id}
          selectedUniversity={selectedUniversity}
          selectedStudentIds={selectedStudentIds}
          setSelectedStudentIds={setSelectedStudentIds}
          studentList={studentList}
          setRecallApi={setRecallApi}
          StudentListData={() => StudentListData()}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={totalItems}
          setCurrentPage={handlePageChange} // Pass function to change page
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>

      {/* <Modal show={showAssignModal} onHide={handleAssignClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Assign Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          
            <Form.Group controlId='dateAndCount' className='mb-2'>
              <Row>
                <Col md={4}>
                  <Form.Label>Pursuing Year</Form.Label>
                  <select
                    id='academicYear'
                    value={academicYear}
                    onChange={handleAcademicYearChange}
                    className='form-control'
                  >
                    {academicYearData?.map(item => (
                      <option key={item?.u_id} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Form.Group>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAssign}>
            Confirm Assign Course
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* Assign Courses Modal */}
      <Modal show={showAssignModal} onHide={handleAssignClose}>
        <Modal.Header closeButton>
          <Modal.Title>Promote student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='dateAndCount' className='mb-2'>
            <Row>
              <Col md={12}>
                <Form.Label>Pursuing Year</Form.Label>
                <select
                  id='academicYear'
                  value={promotedYear}
                  onChange={handlePromotesYearChange}
                  className='form-control'
                >
                  {academicYearArchiveData?.map(item => (
                    <option key={item?.u_id} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleAssignClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleAssign}>
            {'Confirm'}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Disclaimer Modal */}
      <Modal show={showDisclaimerModal} onHide={handleDisclaimerClose}>
        <Modal.Header closeButton>
          <Modal.Title>Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {promotedYear === 'archive' ? (
            <p>
              You are about to archive {selectedStudentIds?.length} student(s). This action will move students from the{' '}
              current academic year to an archived state. Please note that archiving these students will remove them
              from active listings, but their data will still be retained. Are you sure you want to proceed?
            </p>
          ) : (
            <p>
              You are about to promote {selectedStudentIds?.length} student(s). This promotion will apply to students
              from {promotedYear} Year. Please note that if there are any students already promoted, their previous
              courses will be removed. Are you sure you want to continue?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleDisclaimerClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleConfirmAssign}>
            {promotedYear === 'archive' ? 'Yes, Archive Students ' : 'Yes, Promote Students'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='approvalStatus'>
              <Form.Label>Select Status</Form.Label>
              <Form.Control as='select' value={selectedApproveStatus || ''} onChange={handelChangeApprove}>
                <option value='' disabled>
                  Choose...
                </option>
                <option value='approve'>Approve</option>
                <option value='pending'>Pending</option>
                <option value='cancel'>Cancel</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={approveStatus} disabled={!selectedApproveStatus || !selectedCourses}>
            Yes, approve it!
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PromoteStudentList
