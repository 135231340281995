import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import { Col, Row, Form, Button, Container, InputGroup } from '@themesberg/react-bootstrap'
import BgImage from '../../assets/img/illustrations/signin.svg'
// import { app } from '../../config/firebase'
import { callApi } from '../../utils/api-utils'
import { useUserContext } from '../../context/MyContext'

const defaults = {
  methods: {
    GET: {
      method: 'GET'
    },
    POST: {
      method: 'POST'
    },
    PUT: {
      method: 'PUT'
    },
    DELETE: {
      method: 'DELETE'
    },
    PATCH: {
      method: 'PATCH'
    }
  }
}
const loginEndPoint = {
  ...defaults.methods.POST,
  uri: '/api/login'
}

export default () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState('Sign in')
  const [showPassword, setShowPassword] = useState(false)
  const { login } = useUserContext()

  async function Login(e) {
    e.preventDefault()
    const body = { email, password }
    setLoading('Loading...')
    await callApi({ uriEndPoint: loginEndPoint, body: body })
      .then(res => {
        setLoading('Sign in')
        console.log('res.data :', res.data)
        login(res.data)
        localStorage.setItem('loginData', JSON.stringify(res.data))
        alert(res?.message)
        window.location.reload()
      })
      .catch(e => {
        console.log(e)
        alert(e.message)
        setLoading('Sign in')
      })
    // if (email.length != 0 && password.length != 0) {
    //   setLoading('Loading...')
    //   app
    //     .auth()
    //     .signInWithEmailAndPassword(email, password)
    //     .then(() => {
    //       const id = app.auth().currentUser.uid

    //       if (id === 'mK7yKtUhsphM7xufxU6j6ASsB3v2') {
    //         setLoading('Sign in')
    //         alert('Welcome to Admin Dashboard')
    //       } else {
    //         setLoading('Sign in')
    //         alert("You aren't admin")
    //         app
    //           .auth()
    //           .signOut()
    //           .then(() => (window.location.href = '/'))
    //       }
    //     })
    //     .catch(err => {
    //       setLoading('Sign in')
    //       alert(err.message)
    //     })
    // }
  }

  return (
    <main>
      <section className='d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
        <Container>
          <p className='text-center'></p>
          <Row className='justify-content-center form-bg-image' style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className='d-flex align-items-center justify-content-center'>
              <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                <div className='text-center text-md-center mb-4 mt-md-0'>
                  <h3 className='mb-0'>Sign in to SHAH ADMIN</h3>
                </div>
                <Form onSubmit={Login} className='mt-4'>
                  <Form.Group id='email' className='mb-4'>
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoFocus
                        required
                        type='email'
                        placeholder='example@company.com'
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id='password' className='mb-4'>
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          required
                          type={showPassword ? 'text' : 'password'}
                          placeholder='Password'
                        />

                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: 'pointer', marginLeft: '0px', borderRight: '1px solid #ced4da' }}
                        >
                          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                  </Form.Group>
                  <Button variant='primary' type='submit' className='w-100'>
                    {loading}
                  </Button>
                </Form>

                <div className='d-flex justify-content-center align-items-center mt-4'>
                  <span className='fw-normal'>SHAH &copy; {new Date().getFullYear().toString()}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
