import React from 'react'
import { Toaster } from 'react-hot-toast'

function ReactToast() {
  return (
    <div>
      {' '}
      <Toaster
        position='top-right'
        containerStyle={{
          top: 100,
          right: 20,
          zIndex: 999999
        }}
      />
    </div>
  )
}

export default ReactToast
